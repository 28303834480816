import { FC, useMemo } from 'react'
import classNames from 'classnames'

export interface CheckboxProps {
  label: string
  value: boolean
  onChange: (e: any) => void
  disabled?: boolean
}

const Checkbox: FC<CheckboxProps> = ({
  label,
  value,
  onChange,
  disabled = false,
}) => {
  const tag = useMemo(() => `textinput_${label}`, [label])
  return (
    <div className='flex items-center'>
      <input
        disabled={disabled}
        key={tag}
        type='checkbox'
        name={tag}
        id={tag}
        className='focus:ring-indigo-500 focus:border-indigo-500 rounded-none rounded-r-md sm:text-sm border-gray-300 p-2'
        onChange={onChange}
        checked={value}
      />
      <label htmlFor={tag} className='ml-3 text-sm font-medium text-gray-700'>
        {label}
      </label>
    </div>
  )
}

export default Checkbox
