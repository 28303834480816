import { gql } from '@apollo/client'
export const baseUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:4000/dev/graphql'
    : 'https://qgrjjyhfja.execute-api.us-west-2.amazonaws.com/prod/graphql'

export const loginQuery = gql`
  # Write your query or mutation here
  query ADMCMSAuthorization($username: String!, $password: String!) {
    authorization(username: $username, password: $password) {
      vimeoClientId
      vimeoAccessToken
      vimeoClientSecret
      contentfulSpaceId
      contentfulPrivateToken
      contentfulProductionToken
    }
  }
`
