import { FC } from 'react'

interface VimeoVideoProps {
  id: string
}

export const VimeoVideo: FC<VimeoVideoProps> = ({ id }) => {
  const playerUrl = `https://player.vimeo.com/video/${id}`
  return (
    <iframe
      title={'vimeo-video-player'}
      src={playerUrl}
      width='100%'
      height='360'
      frameBorder='0'
      allow='fullscreen; picture-in-picture'
      allowFullScreen
    ></iframe>
  )
}
