import { ApolloClient, InMemoryCache, gql } from '@apollo/client'

import { Environment } from './contentfulApi'

export const settingsNodeId = '27AIxUdlnBoYxG9FlXWBPx'

const drawerItemCollectionQuery = gql`
  query {
    drawerItemCollection {
      items {
        sys {
          id
        }
        title
        contentMobile
        contentHtml
        mediaLabel
        mediaImage {
          title
          description
          url
          width
          height
        }
        mediaId
      }
    }
  }
`
const detailPageCollectionQuery = gql`
  query {
    detailPageCollection {
      items {
        sys {
          id
          publishedAt
          publishedVersion
        }
        title
        subtitle
        location {
          lat
          lon
        }
        contentMobile
        contentHtml
        mediaLabel
        mediaType
        mediaId
        mediaImage {
          sys {
            id
          }
          title
          url
          height
          width
        }
        hasShare
        shareLabelText
        shareUrl
      }
    }
  }
`
export const mobileAppSettingsQuery = gql`
  query {
    mobileAppSettingsCollection {
      items {
        title
        mapOverlaySizeRatio
      }
    }
  }
`

export const combinedQuery = gql`
  query combinedQuery($settingsNodeId: String!) {
    drawerItemCollection(order: [order_ASC]) {
      items {
        sys {
          id
        }
        title
        contentMobile
        contentHtml
        mediaLabel
        mediaImage {
          sys {
            id
          }
          url
        }
        order
      }
    }
    detailPageCollection {
      items {
        sys {
          id
          publishedAt
          publishedVersion
        }
        title
        subtitle
        location {
          lat
          lon
        }
        contentMobile
        contentHtml
        mediaLabel
        mediaType
        mediaId
        mediaImage {
          sys {
            id
          }
          title
          url
          height
          width
        }
        hasShare
        shareLabelText
        shareUrl
      }
    }
    mobileAppSettings(id: $settingsNodeId) {
      sys {
        id
      }
      mapOverlaySizeRatio
      mapOverlayTransparency
    }
  }
`

const baseUrl = 'https://graphql.contentful.com/content/v1/spaces'

export const createApolloClient: any = (spaceId: string, token: string) =>
  new ApolloClient({
    uri: `${baseUrl}/${spaceId}/environments/${Environment.MASTER}`,
    cache: new InMemoryCache(),
    headers: {
      authorization: `Bearer ${token}`,
    },
  })
