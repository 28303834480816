import { FC } from 'react'

interface RadioItem<T> {
  label: string
  value: T
}

export interface RadioInputProps<T = string> {
  selectedOption?: string
  items: RadioItem<T>[]
  label: string
  onChange: (e: any) => void
  disabled: boolean
}

const RadioInput: FC<RadioInputProps> = ({
  items,
  label,
  onChange,
  selectedOption,
  disabled,
}) => {
  return (
    <div className='grid grid-cols-3 gap-6'>
      <div className='col-span-3 sm:col-span-2'>
        <label className='block font-medium text-gray-700'>{label}</label>
        {items.map(({ label, value }) => {
          return (
            <div key={label} className='flex flex-row items-center my-2'>
              <input
                type='radio'
                disabled={disabled}
                checked={value === selectedOption}
                name={label}
                id={value}
                onChange={() => onChange(value)}
                className=''
                placeholder='Add your title here'
              />
              <label
                htmlFor={value}
                className='block text-xs pl-2 font-medium text-gray-700'
              >
                {label}
              </label>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default RadioInput
