import { useContext } from 'react'

import { AppContext } from '../../App/App.context'
import { PageBaseUrlVariant } from '../../../types/sharedTypes'
import CardList from '../../CardList/CardList'

/**
 * DetailsHome renders all DetailsPage nodes alongside the map
 */
const DrawerItemsHome = () => {
  const { drawerItemCollectionList } = useContext(AppContext)

  return (
    <CardList
      items={drawerItemCollectionList}
      title='Drawer Items'
      pageBaseUrl={PageBaseUrlVariant.DRAWER}
    />
  )
}

export default DrawerItemsHome
