import React, { ReactElement, useMemo } from 'react'
import classnames from 'classnames'
import { useLocation, Link } from 'react-router-dom'
import { capitalize, createLink } from '../../utils/helpers'

const homeRoute = {
  icon: (
    <svg
      className='flex-shrink-0 h-5 w-5'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 20 20'
      fill='currentColor'
      aria-hidden='true'
    >
      <path d='M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z' />
    </svg>
  ),
  link: '/',
  name: 'Home',
}

interface NavigationItem {
  icon?: ReactElement
  link: string
  name: string
}

const arrowSvg = (
  <svg
    className='flex-shrink-0 w-6 h-full text-gray-200'
    viewBox='0 0 24 44'
    preserveAspectRatio='none'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    aria-hidden='true'
  >
    <path d='M.293 0l22 22-22 22h1.414l22-22-22-22H.293z' />
  </svg>
)

const allowedClickableRoutes = new Set([
  'View',
  'Settings',
  'Drawer',
  'Details',
  'Home',
])

const Navbar = () => {
  const location = useLocation()
  const navigationItems = useMemo((): NavigationItem[] => {
    // return [homeRoute]
    if (location.pathname === '/') {
      return [homeRoute]
    }

    const routes: string[] = location.pathname.slice(1).split('/')

    const routeItems: NavigationItem[] = [
      homeRoute,
      ...routes.map((route, index) => ({
        link: createLink(routes, index),
        name: `${capitalize(route)}`,
      })),
    ]

    return routeItems
  }, [location.pathname])

  return (
    <nav
      className='flex w-screen my-2 h-12 absolute top-0 left-0 z-50 justify-between'
      aria-label='Breadcrumb'
    >
      <ol className='bg-white rounded-md shadow px-6 flex space-x-4'>
        {navigationItems.map(({ icon, link, name }, index) => {
          const children = (
            <>
              {icon}
              <span className='ml-2'>{name}</span>
            </>
          )
          const className = classnames('flex items-center justify-center', {
            'text-gray-400 hover:text-gray-500': index === 0,
            'ml-4 text-sm font-medium text-gray-500': index !== 0,
          })

          return (
            <li key={name} className='flex h-12'>
              <div className='flex items-center'>
                {index !== 0 && arrowSvg}
                {allowedClickableRoutes.has(name) ? (
                  <Link to={link} className={className}>
                    {children}
                  </Link>
                ) : (
                  <div className={className}>{children}</div>
                )}
              </div>
            </li>
          )
        })}
      </ol>
    </nav>
  )
}

export default Navbar
