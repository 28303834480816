import { FC, useCallback, useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'

import { FileData } from '../../services/contentful/contentfulApi'
import { Button, ButtonVariant } from '../Button/Button'
import { VimeoVideo } from '../VimeoVideo/VimeoVideo'
import { ErrorProps } from '../../types/sharedTypes'

export enum FileMediaTypes {
  IMAGE = 'image',
  VIDEO = 'video',
}

export interface DragDropUploadProps extends Partial<ErrorProps> {
  label: string
  mediaLabel: string
  mediaType: FileMediaTypes
  onChange: (file: FileData | null) => void
  url?: string
  disabled: boolean
  children?: any
  mediaId?: string
}

const allowedFileTypesObject = {
  image: ['image/png', 'image/webp', 'image/jpg', 'image/gif'],
  video: ['video/mp4', 'video/webm'],
}

const createFileReader = (callback: (data: any) => void) => {
  const reader = new FileReader()

  reader.onabort = () => console.log('file reading was aborted')
  reader.onerror = () => console.log('file reading has failed')
  reader.onload = () => {
    callback(reader.result)
  }

  return reader
}

const DragDropUpload: FC<DragDropUploadProps> = ({
  label,
  mediaType,
  onChange,
  url,
  disabled,
  mediaLabel,
  mediaId,
  hasError,
  errorText,
}) => {
  const fileTypeText =
    mediaType === FileMediaTypes.IMAGE
      ? 'PNG, JPG, WEBP, GIF up to 10MB'
      : 'MP4'

  const acceptedFileTypes = allowedFileTypesObject[mediaType]
  // Internal state file
  const [activeFile, setActiveFile] = useState<FileData | null>(null)

  const onDrop = useCallback(([acceptedFile]) => {
    if (acceptedFile?.type.match('image/')) {
      /**
       * Read the files as an array buffer to upload a file
       * Read files as a DataUrl to preview file
       */
      // const readerPromises = [fileUrlPromise, fileBufferPromise]
      createFileReader((fileUrl) => {
        createFileReader((result) => {
          const data = {
            mediaLabel,
            url: fileUrl,
            upload: result,
            fileName: mediaLabel || acceptedFile.name,
            contentType: acceptedFile.type,
          }
          setActiveFile(data)
          onChange(data)
        }).readAsArrayBuffer(acceptedFile)
      }).readAsDataURL(acceptedFile)
      return
    }

    if (acceptedFile?.type.match('video/')) {
      createFileReader((buffer) => {
        const data = {
          upload: buffer,
          fileName: acceptedFile.name,
          name: mediaLabel || acceptedFile.name,
          contentType: acceptedFile.type,
          size: acceptedFile.size,
        }
        setActiveFile(data)
        onChange(data)
      }).readAsArrayBuffer(acceptedFile)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
  })

  const clearActiveFile = () => {
    setActiveFile(null)
    onChange(null)
  }

  /**
   * Clears and upload file if mediaType is changed
   */
  useEffect(() => {
    if (mediaType && activeFile) {
      if (
        !activeFile.contentType.match(
          mediaType === FileMediaTypes.IMAGE ? 'image/' : 'video/'
        )
      ) {
        clearActiveFile()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaType, activeFile])

  const renderFileImage = useCallback(() => {
    if (!activeFile && !url) {
      return (
        <div className='space-y-1 text-center'>
          <div>
            <svg
              className='mx-auto h-12 w-12 text-gray-400'
              stroke='currentColor'
              fill='none'
              viewBox='0 0 48 48'
              aria-hidden='true'
            >
              <path
                d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                strokeWidth={2}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            <div className='flex text-sm text-gray-600'>
              <p className='pl-1'>
                {isDragActive
                  ? 'Drop the files here ...'
                  : 'Drag and drop some files here, or click to select files'}
              </p>
            </div>
            <p className='text-xs text-gray-500'>{fileTypeText}</p>
          </div>
        </div>
      )
    }

    if (!activeFile && url) {
      return (
        <div className='flex w-full flex-col'>
          <div className='flex'>
            {mediaType === FileMediaTypes.IMAGE ? (
              <img
                src={url}
                alt={label}
                style={{ width: 'auto', height: '100%', maxHeight: 350 }}
              />
            ) : (
              mediaId && <VimeoVideo id={mediaId} />
            )}
          </div>
        </div>
      )
    }

    if (!activeFile) {
      return null
    }

    const isImageFile =
      mediaType === FileMediaTypes.IMAGE &&
      activeFile?.contentType?.match('image/')
    const FileNameNode = <span>Filename: {activeFile.fileName}</span>

    if (isImageFile && activeFile.url) {
      return (
        <div className='flex w-full flex-col'>
          <div>{FileNameNode}</div>
          <div className='flex'>
            <img
              src={activeFile.url}
              alt={activeFile.fileName}
              style={{ width: 'auto', height: '100%', maxHeight: 350 }}
            />
          </div>
        </div>
      )
    }

    return FileNameNode
  }, [activeFile, url, fileTypeText, label, isDragActive, mediaId, mediaType])

  return (
    <div>
      <div className='flex items-center justify-between'>
        <span>
          <label
            htmlFor='file-upload'
            className='text font-medium text-gray-700'
          >
            {label}
          </label>
          {hasError && <span className='text-red-600 ml-1'>{errorText}</span>}
        </span>
        {activeFile && (
          <Button
            title='Clears the upload file'
            type='button'
            variant={ButtonVariant.RED_CAUTION}
            onClick={clearActiveFile}
          >
            Remove file
          </Button>
        )}
      </div>
      {activeFile && url && (
        <div className='text-xs text-gray-500'>
          Warning! After submitting this file, it will delete the previously
          uploaded file
        </div>
      )}
      <div {...getRootProps()}>
        <input
          {...getInputProps({
            disabled: disabled,
            id: 'file-upload',
            name: 'file-upload',
            style: undefined,
            className: 'sr-only',
            multiple: false,
          })}
        />
        <div>
          <div className='mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md'>
            {renderFileImage()}
          </div>
        </div>
      </div>
    </div>
  )
}

DragDropUpload.defaultProps = {
  errorText: '*',
}

export default DragDropUpload
