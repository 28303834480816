import { FC, useMemo } from 'react'
import classNames from 'classnames'

import { ReactComponent as Edit } from '../../assets/create.svg'
import { ReactComponent as Save } from '../../assets/save.svg'
import { ReactComponent as Delete } from '../../assets/delete.svg'
import { ReactComponent as Restore } from '../../assets/settings_backup_restore.svg'

export enum ButtonIconVariant {
  SAVE,
  EDIT,
  DELETE,
  RESET,
}

export enum ButtonVariant {
  PRIMARY,
  SECONDARY,
  RED_CAUTION,
  BLUE_RESET,
}

interface ButtonProps
  extends Partial<React.ButtonHTMLAttributes<HTMLButtonElement>> {
  variant: ButtonVariant
  iconVariant?: ButtonIconVariant
  hasIcon?: boolean
  onClick?: (e: any) => void
}

export const Button: FC<ButtonProps> = ({
  className,
  variant,
  type,
  onClick,
  disabled,
  children,
  title,
  hasIcon = false,
  iconVariant,
}) => {
  const icon = useMemo(() => {
    if (!hasIcon) {
      return null
    }

    switch (iconVariant) {
      case ButtonIconVariant.DELETE:
        return (
          <Delete
            className={'w-4 mr-1 fill-current text-white'}
            title={title}
          />
        )
      case ButtonIconVariant.SAVE:
        return (
          <Save className={'w-4 mr-1 fill-current text-white'} title={title} />
        )
      case ButtonIconVariant.EDIT:
        return (
          <Edit className={'w-4 mr-1 fill-current text-white'} title={title} />
        )
      case ButtonIconVariant.RESET:
        return (
          <Restore
            className={'w-4 mr-1 fill-current text-white'}
            title={title}
          />
        )
    }
  }, [hasIcon, iconVariant, title])

  return (
    <button
      disabled={disabled}
      type={type}
      className={classNames(
        'flex items-center justify-center py-2 px-4 border border-transparent shadow-sm font-medium rounded-md text-white',
        {
          'bg-red-500 hover:bg-red-400':
            variant === ButtonVariant.RED_CAUTION && !disabled,
          'bg-blue-500 hover:bg-blue-400':
            variant === ButtonVariant.BLUE_RESET && !disabled,
          'bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500':
            variant === ButtonVariant.PRIMARY && !disabled,
          'bg-gray-500 opacity-80 hover:bg-transparent': disabled,
        },
        className
      )}
      title={title}
      onClick={onClick}
    >
      {icon}
      {children || title}
    </button>
  )
}
