import { FC, RefObject, SyntheticEvent, useEffect, useState } from 'react'
import classNames from 'classnames'
import { PageActionVariant } from '../../types/sharedTypes'

import { ReactComponent as AddCircle } from '../../assets/add_circle.svg'
import { ReactComponent as ViewOnly } from '../../assets/edit_off.svg'
import { ReactComponent as Edit } from '../../assets/create.svg'

export enum PageCardVariant {
  PRIMARY,
  SECONDARY,
}

export interface PageCardProps {
  ref?: RefObject<any>
  variant: PageCardVariant
  title: string
  focused?: boolean
  onClick: (option: PageActionVariant) => void
  buttonOptions: PageActionVariant[]
  id: string
  onViewClick?: () => void
  hasFocusIndicator?: boolean
  className?: any
  onHover?: (isFocused: boolean) => () => void
}

const PageCard: FC<PageCardProps> = ({
  buttonOptions,
  focused,
  onClick,
  title,
  variant,
  onViewClick,
  id,
  hasFocusIndicator = false,
  className,
  onHover,
}) => {
  const [hasFocus, setFocus] = useState(false)

  /**
   * Allows for both internal and external control of card focus
   */
  useEffect(() => {
    if (focused !== undefined) {
      setFocus(focused)
    }
  }, [focused])

  const getIcon = (option: PageActionVariant) => {
    switch (option) {
      case PageActionVariant.ADD:
        return (
          <AddCircle
            className={classNames(`w-8 fill-current`, {
              'text-black': !hasFocus,
              'text-white': hasFocus,
            })}
            title={PageActionVariant.ADD}
          />
        )
      case PageActionVariant.EDIT:
        return (
          <Edit
            className={classNames(`w-8 fill-current`, {
              'text-gray-600': !hasFocus,
              'text-white': hasFocus,
            })}
            title={PageActionVariant.ADD}
          />
        )
    }
  }

  const handleViewClick = () => {
    if (variant !== PageCardVariant.PRIMARY && onViewClick) {
      onViewClick()
    }
  }

  const handleOnClick = (e: SyntheticEvent, option: PageActionVariant) => {
    e.preventDefault()
    e.stopPropagation()

    onClick(option)
  }

  const handleMouse = (isEnter: boolean) => () => {
    if (onHover !== undefined) {
      setFocus(isEnter)
      onHover(isEnter)
    }
  }

  return (
    <div
      id={`page_card_${id}`}
      onMouseEnter={handleMouse(true)}
      onMouseLeave={handleMouse(false)}
      onClick={handleViewClick}
      className={classNames(
        'cursor-pointer relative border-b-2 w-full py-6 sm:px-6',
        {
          'bg-indigo-600': hasFocus,
        },
        className
      )}
    >
      <div className='pl-4 flex h-full items-center flex-wrap sm:flex-nowrap justify-between'>
        <h3
          className={classNames('leading-6 font-medium w-3/4', {
            'text-gray-900': !hasFocus,
            'text-white': hasFocus,
            'text-3xl': variant === PageCardVariant.PRIMARY,
            'text-lg': variant === PageCardVariant.SECONDARY,
          })}
        >
          {title}
        </h3>
        {buttonOptions.map((option) => (
          <button
            key={option}
            onClick={(e: SyntheticEvent) => handleOnClick(e, option)}
            type='button'
            className={classNames('absolute right-6 inset-center p-2')}
          >
            {getIcon(option)}
          </button>
        ))}
      </div>
    </div>
  )
}

export default PageCard
