import { FC, useMemo, useContext } from 'react'

import { Button, ButtonVariant } from '../Button/Button'
import { PageActionVariant, PageBaseUrlVariant } from '../../types/sharedTypes'
import { AppContext } from '../App/App.context'

const Home: FC<UserProps & Page> = ({ history }) => {
  const context = useContext(AppContext)
  const buttons = useMemo(
    () => [
      {
        title: 'Details Pages',
        onClick: () => history.push(`/${PageBaseUrlVariant.DETAILS}`),
      },
      {
        title: 'App Drawer Pages',
        onClick: () => history.push(`/${PageBaseUrlVariant.DRAWER}`),
      },
      {
        title: 'App Settings',
        onClick: () =>
          history.push(
            `/${PageBaseUrlVariant.APP_SETTINGS}/${PageActionVariant.VIEW}`
          ),
      },
    ],
    [history]
  )

  const handleLogoff = () => {
    history.push('./login')

    if (context && context.onLogoff) {
      context?.onLogoff()
    }
  }

  return (
    <div className='pt-16 w-screen h-screen flex items-center justify-center flex-col'>
      {buttons.map(({ title, onClick }) => (
        <Button
          key={title}
          className='my-4'
          variant={ButtonVariant.PRIMARY}
          onClick={onClick}
          title={title}
        >
          {title}
        </Button>
      ))}
      <Button
        className='my-4'
        variant={ButtonVariant.RED_CAUTION}
        title='Sign out'
        onClick={handleLogoff}
      />
    </div>
  )
}

export default Home
