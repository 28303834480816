export enum ContentType {
  VIDEO = 'video',
  TEXT = 'text',
  TEXT_ITALIC = 'textItalic',
  TEXT_BOLD = 'textBold',
  TEXT_ITALIC_BOLD = 'textItalicBold',
  TEXT_BLOCK = 'textBlock', // used to combine links and other complex items into a single line of text
  LINK = 'link',
  UNORDERED_LIST = 'unorderedList',
  IMAGE = 'image',
  SHARE = 'share',
  HEADLINE_ONE = 'headline_one',
  SUBTITLE = 'subtitle',
  LINEBREAK = 'linebreak',
}

interface UnorderedListType {
  type: ContentType.UNORDERED_LIST
  list: string[] | ContentTypes[]
}

export interface VideoContentType {
  alttext: string
  text: string
  type: ContentType.VIDEO
  url: string
}

interface ImageSourceStubType {
  uri: string
}

interface ImageContentType {
  alttext?: string
  text?: string
  type: ContentType.IMAGE
  url: string
  source?: ImageSourceStubType
}

export interface TextContentType {
  text: string
  styleOverride?: string // 1:1 match with styles object of detail screen
  type:
    | ContentType.TEXT
    | ContentType.TEXT_BOLD
    | ContentType.TEXT_ITALIC
    | ContentType.TEXT_ITALIC_BOLD
}

interface HeadlineOneContentType {
  text: string
  styleOverride?: string // 1:1 match with styles object of detail screen
  type: ContentType.HEADLINE_ONE
}

interface SubtitleContentType extends Omit<HeadlineOneContentType, 'type'> {
  type: ContentType.SUBTITLE
}

interface LinkType {
  text: string
  url: string
  type: ContentType.LINK
}

interface ShareType {
  text: string
  type: ContentType.SHARE
}

export interface TextBlockType {
  type: ContentType.TEXT_BLOCK
  nodes: Omit<ContentTypes, 'TextBlockType'>[]
  styleOverride?: string
}

export interface LineBreakType {
  type: ContentType.LINEBREAK
}

export interface StyleOverride {
  textStyle?: string | ContentType
  color?: string
}

type baseContentTypes =
  | VideoContentType
  | ImageContentType
  | TextContentType
  | LinkType
  | ShareType
  | HeadlineOneContentType
  | SubtitleContentType
  | UnorderedListType
  | TextBlockType
  | LineBreakType

export type ContentTypes = baseContentTypes & {
  styleOverride?: StyleOverride
}
