import { createContext } from 'react'

interface AuthorizationRecord {
  vimeoClientId: string
  vimeoClientSecret: string
  vimeoAccessToken: string
  contentfulSpaceId: string
  contentfulPrivateToken: string
  contentfulProductionToken: string
}

export interface IAppContext extends Partial<AuthorizationRecord> {
  contentfulApiInstance: any | null
  detailPageCollectionList: Array<any>
  detailPageCollection: Record<string, any>
  drawerItemCollectionList: Array<any>
  drawerItemCollection: Record<string, any>
  mobileAppSettings: Record<string, any>
  onUpdate?: () => void
  onLogoff?: () => void
  isAuthorized: boolean
}

export const initialAppContext = {
  contentfulApiInstance: null,
  detailPageCollectionList: [],
  detailPageCollection: {},
  drawerItemCollectionList: [],
  drawerItemCollection: {},
  mobileAppSettings: {},
  isAuthorized: false,
}

export const AppContext = createContext<IAppContext>(initialAppContext)
