import { FC, useMemo } from 'react'
import classNames from 'classnames'
import { ErrorProps } from '../../types/sharedTypes'

export enum TextInputVariant {
  FLEXIBLE,
  INLINE,
}

export enum TextInputValueType {
  NUMBER = 'number',
  TEXT = 'text',
  RANGE = 'range',
  PASSWORD = 'password',
}

export interface TextInputProps extends Partial<HTMLInputElement & ErrorProps> {
  label: string
  description?: string
  variant?: TextInputVariant
  valueType?: TextInputValueType
  onChange: (e: any) => void
}

const TextInput: FC<TextInputProps> = ({
  label,
  placeholder = 'type here',
  variant = TextInputVariant.INLINE,
  value,
  disabled = false,
  description,
  valueType,
  min,
  max,
  step,
  className,
  hasError,
  errorText,
  onChange,
}) => {
  const tag = useMemo(() => `textinput_${label}`, [label])

  const labelText = useMemo(() => {
    if (valueType === TextInputValueType.RANGE) {
      return `${label} ${value}`
    }
    return label
  }, [label, value, valueType])

  return (
    <div
      className={classNames(
        {
          'grid grid-cols-3 gap-6': variant === TextInputVariant.INLINE,
        },
        className
      )}
    >
      <div className='col-span-3 sm:col-span-2'>
        <label htmlFor={tag} className='text font-medium text-gray-700'>
          {labelText}
        </label>
        {hasError && <span className='text-red-600 ml-1'>{errorText}</span>}
        {description && <p className='text-sm text-gray-500'>{description}</p>}
        <div className='mt-1 flex rounded-md shadow border-2'>
          <input
            min={min}
            max={max}
            step={step}
            type={valueType}
            disabled={disabled}
            key={tag}
            name={tag}
            id={tag}
            className='focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300 bg-white px-4 py-2'
            placeholder={placeholder}
            onChange={onChange}
            value={value}
          />
        </div>
      </div>
    </div>
  )
}

TextInput.defaultProps = {
  errorText: '*',
}

export default TextInput
