import { FC, useState, useMemo, useEffect, FormEvent } from 'react'
import {
  useLazyQuery,
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
} from '@apollo/client'
import Loader, { LoaderVariant } from '../Loader/Loader'

import { Button, ButtonVariant } from '../Button/Button'
import { baseUrl, loginQuery } from '../../services/cms-auth/cms-auth'
import TextInput, {
  TextInputValueType,
  TextInputVariant,
} from '../Inputs/TextInput'
import { IAppContext } from '../App/App.context'
import { secureLocalStorage } from '../../utils/storage'

const loaderDimensions = 200

interface LoginProps {
  setContext(c: Partial<IAppContext>): void
}

const loginClient = new ApolloClient({
  uri: baseUrl,
  cache: new InMemoryCache(),
})
const defaultFormState = { username: '', password: '' }

const isInvalid = (data: (string | null)[]) =>
  new Set(Object.values(data)).has(null)

const Login: FC<LoginProps> = ({ setContext }) => {
  const [formState, setFormState] = useState(defaultFormState)
  const [submitData, setSubmitData] = useState(defaultFormState)

  const [
    getAuthorization,
    { loading, data, error, networkStatus },
  ] = useLazyQuery(loginQuery, {
    variables: {
      username: submitData.username,
      password: submitData.password,
    },
  })

  const handleTextChange = (fieldId: string) => (
    e: InputEvent & { target: HTMLInputElement }
  ) => {
    e.preventDefault()

    if (e.target.value === null || e.target.value === undefined) {
      return
    }

    setFormState((s) => ({ ...s, [fieldId]: e.target.value }))
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setSubmitData(formState)
  }

  useEffect(() => {
    if (submitData.password && submitData.username) {
      getAuthorization()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitData])

  useEffect(() => {
    if (!loading && data && !isInvalid(data || {})) {
      Object.entries(data.authorization).forEach(([key, val]) => {
        if (key === '__typename') {
          return
        }
        secureLocalStorage.set(key, val)
      })

      setContext({ ...data.authorization, isAuthorized: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading])

  const hasError = useMemo(() => {
    if (data && !loading) {
      return !isInvalid(data)
    }
    return false
  }, [data, loading])

  return (
    <div className='flex w-screen h-screen items-center justify-center flex-col'>
      <form className='lg:w-1/4' onSubmit={handleSubmit}>
        <TextInput
          variant={TextInputVariant.FLEXIBLE}
          className='mb-4 w-full'
          label='Username'
          value={formState.username}
          onChange={handleTextChange('username')}
        />
        <TextInput
          variant={TextInputVariant.FLEXIBLE}
          className='mb-4'
          label='Password'
          valueType={TextInputValueType.PASSWORD}
          value={formState.password}
          onChange={handleTextChange('password')}
        />
        <Button
          className='w-24 ml-auto'
          variant={ButtonVariant.PRIMARY}
          title='Login'
          type='submit'
          disabled={loading}
        >
          {!loading ? (
            'Login'
          ) : (
            <Loader
              type={LoaderVariant.SPIN}
              height={25}
              width={25}
              color='white'
            />
          )}
        </Button>
        {hasError && (
          <div className='text-red-500 text-lg mt-4'>
            Your username or password is incorrect, try again
          </div>
        )}
      </form>
    </div>
  )
}

const LoginContainer: FC<LoginProps> = (props) => (
  <ApolloProvider client={loginClient}>
    <Login {...props} />
  </ApolloProvider>
)

export default LoginContainer
