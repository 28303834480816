export interface ErrorProps {
  hasError: boolean
  errorText: string
}

export enum PageActionVariant {
  ADD = 'add',
  EDIT = 'edit',
  VIEW = 'view',
}

export enum PageBaseUrlVariant {
  DETAILS = 'details',
  DRAWER = 'drawer',
  APP_SETTINGS = 'settings',
}
