import { FC } from 'react'
import ReactLoading from 'react-loading'

export enum LoaderVariant {
  SPIN = 'spin',
  BARS = 'bars',
}

interface LoaderProps {
  className?: any
  height: number
  width: number
  type?: LoaderVariant
  color?: string
}

const Loader: FC<LoaderProps> = (props) => <ReactLoading {...props} />

Loader.defaultProps = {
  type: LoaderVariant.SPIN,
  color: 'blue',
}

export default Loader
