import { FC } from 'react'
import { useHistory } from 'react-router'

import PageCard, { PageCardProps, PageCardVariant } from '../PageCard/PageCard'
import { navigateToPage } from '../../utils/helpers'
import { PageActionVariant, PageBaseUrlVariant } from '../../types/sharedTypes'

import './CardList.style.css'

interface CardListProps {
  items: PageCardProps[]
  title: string
  children?: any
  focusId?: string | null
  pageBaseUrl: PageBaseUrlVariant
  onHover?: (isFocused: boolean) => () => void
}

/**
 * Renders a bunch of page cards for pages like DetailPage or DrawerItemPage
 */
const CardList: FC<CardListProps> = ({
  children,
  focusId,
  items,
  title,
  pageBaseUrl,
}) => {
  const history = useHistory()

  return (
    <div className='flex w-screen h-screen'>
      <div className='card-list__items flex flex-col absolute z-10 rounded-tr-3xl'>
        <PageCard
          variant={PageCardVariant.PRIMARY}
          id={PageActionVariant.ADD}
          title={title}
          onClick={navigateToPage(history, pageBaseUrl)}
          buttonOptions={[PageActionVariant.ADD]}
        />
        <div className='overflow-auto'>
          {items.map(
            (card: Omit<PageCardProps, 'buttonOptions' | 'onClick'>) => (
              <PageCard
                {...card}
                hasFocusIndicator
                key={card.id}
                variant={PageCardVariant.SECONDARY}
                focused={card.id === focusId}
                onClick={navigateToPage(history, pageBaseUrl, card.id)}
                onViewClick={() =>
                  navigateToPage(
                    history,
                    pageBaseUrl,
                    card.id
                  )(PageActionVariant.VIEW)
                }
                buttonOptions={[PageActionVariant.EDIT]}
              />
            )
          )}
        </div>
      </div>
      {children}
    </div>
  )
}

export default CardList
