import { FC } from 'react'
import ReactQuill from 'react-quill'
import classNames from 'classnames'

/**
 * required for quill styles to be applied
 */
import 'react-quill/dist/quill.snow.css' // ES6
import './RichTextInput.css'

export interface RichTextInputProps {
  label: string
  value: any
  onChange: (e: any, delta: any, source: any, editor: any) => void
  disabled?: boolean
}

const quillConfig = {
  modules: {
    toolbar: [
      [{ header: [1, false] }],
      ['bold', 'italic'],
      // [{ color: { blue: 'blue' } }],
      [
        {
          color: [
            '#000000',
            '#e60000',
            '#ff9900',
            '#ffff00',
            '#008a00',
            '#0066cc',
            '#9933ff',
            '#ffffff',
            '#facccc',
            '#ffebcc',
            '#ffffcc',
            '#cce8cc',
            '#cce0f5',
            '#ebd6ff',
            '#bbbbbb',
            '#f06666',
            '#ffc266',
            '#ffff66',
            '#66b966',
            '#66a3e0',
            '#c285ff',
            '#888888',
            '#a10000',
            '#b26b00',
            '#b2b200',
            '#006100',
            '#0047b2',
            '#6b24b2',
            '#444444',
            '#5c0000',
            '#663d00',
            '#666600',
            '#003700',
            '#002966',
            '#3d1466',
          ],
        },
      ],
      [{ list: 'bullet' }],
      ['link'],
      ['clean'],
    ],
  },
  formats: ['header', 'color', 'bold', 'italic', 'list', 'bullet', 'link'],
}

const RichTextInput: FC<RichTextInputProps> = ({
  label,
  value,
  onChange,
  disabled = false,
}) => {
  return (
    <div className='flex flex-col'>
      <label
        htmlFor={`detailpage_${label}`}
        className='block text font-medium text-gray-700'
      >
        {label}
      </label>
      <div className='mt-4 bg-white'>
        <ReactQuill
          className={classNames('sm:max-w-sm md:max-w-md lg:max-w-full', {
            'ql-toolbar-disable bg-gray-50': disabled,
          })}
          theme='snow'
          modules={quillConfig.modules}
          formats={quillConfig.formats}
          value={value}
          readOnly={disabled}
          onChange={!disabled ? onChange : undefined}
        />
      </div>
    </div>
  )
}

export default RichTextInput
