import { FC, useContext, useMemo, useState } from 'react'

import DetailsHomeMap, { MapVariant } from '../Map/Map'
import { PageCardVariant } from '../PageCard/PageCard'
import { AppContext } from '../App/App.context'
import { navigateToPage } from '../../utils/helpers'
import { PageActionVariant, PageBaseUrlVariant } from '../../types/sharedTypes'
import CardList from '../CardList/CardList'

import './DetailsHome.style.css'

/**
 * DetailsHome renders all DetailsPage nodes alongside the map
 */
const DetailsHome: FC<Page> = ({ history }) => {
  const { detailPageCollectionList } = useContext(AppContext)
  const [focusCard, setFocusCard] = useState<string | null>(null)

  const handleMarkerHover = (id: string) => (isFocused: boolean) => () => {
    setFocusCard(isFocused ? id : null)

    const selectedCard = document.getElementById(`page_card_${id}`)
    if (selectedCard) {
      selectedCard.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  const handlePageItemHover = (id: string) => (isFocused: boolean) => {
    setFocusCard(isFocused ? id : null)
  }

  const mapMarkers = useMemo(() => {
    if (!detailPageCollectionList) {
      return []
    }

    return detailPageCollectionList.map(
      ({ id, location, title, mediaType }, index) => ({
        ...location,
        mediaType,
        id,
        focused: id === focusCard,
        onHover: handleMarkerHover(id),
        onClick: () =>
          navigateToPage(
            history,
            PageBaseUrlVariant.DETAILS,
            id
          )(PageActionVariant.VIEW),
        name: title,
      })
    )
  }, [detailPageCollectionList, history, focusCard])

  const cards = detailPageCollectionList.map((card) => ({
    ...card,
    key: card.id,
    variant: PageCardVariant.SECONDARY,
    focused: card.id === focusCard,
    onHover: handlePageItemHover(card.id),
    onClick: navigateToPage(history, PageBaseUrlVariant.DETAILS, card.id),
    onViewClick: () =>
      navigateToPage(
        history,
        PageBaseUrlVariant.DETAILS,
        card.id
      )(PageActionVariant.VIEW),
    buttonOptions: [PageActionVariant.EDIT],
  }))

  return (
    <CardList
      items={cards}
      title='Detail Pages'
      focusId={focusCard}
      pageBaseUrl={PageBaseUrlVariant.DETAILS}
    >
      <DetailsHomeMap variant={MapVariant.DEFAULT} markers={mapMarkers} />
    </CardList>
  )
}

export default DetailsHome
