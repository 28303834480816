import {
  FormVariant,
  FormPageContentNode,
} from './../components/FormPage/FormPage.types'
import { PageBaseUrlVariant } from './../types/sharedTypes'
import { IAppContext } from '../components/App/App.context'
import { PageActionVariant } from '../types/sharedTypes'

export const isNullOrUndefined = (
  val: null | undefined | string | number
): boolean => val === undefined || val === null

export const capitalize = (s: string) =>
  `${s.charAt(0).toUpperCase()}${s.slice(1)}`

export const createLink = (routes: string[], index: number) =>
  `/${routes.slice(0, index + 1).join('/')}`

export interface MakeCancelable {
  promise: Promise<any>
  cancel(): void
}

export const makeCancelable = (promise: Promise<any>): MakeCancelable => {
  let isCanceled = false
  const wrappedPromise = new Promise((resolve, reject) => {
    promise
      .then((val) =>
        isCanceled ? reject({ isCanceled: true, returnVal: val }) : resolve(val)
      )
      .catch((error) =>
        isCanceled ? reject({ isCanceled: true }) : reject(error)
      )
  })
  return {
    promise: wrappedPromise,
    cancel() {
      isCanceled = true
    },
  }
}

export const navigateToPage = (
  history: any,
  baseUrl: PageBaseUrlVariant | FormVariant,
  pageId?: string
) => (pageAction: PageActionVariant) => {
  // Prevents a empty id from being added to the url
  const url = `/${baseUrl}/page/${[pageId, pageAction]
    .filter((val) => val)
    .join('/')}`

  history.push(url)
}

interface ContentfulContentNode extends FormPageContentNode {
  id: string
  sys: {
    id: string
  }
}

const parseSingleDrawerItem = (data: ContentfulContentNode) => {
  const {
    sys: { id },
    title,
    contentMobile,
    contentHtml,
    mediaLabel,
    mediaImage,
    order,
  } = data

  return {
    id,
    title,
    contentMobile,
    contentHtml,
    mediaLabel,
    order,
    mediaImage: {
      id: mediaImage?.sys?.id,
      url: mediaImage?.url,
      title: mediaImage?.title,
    },
  }
}

export const parseDrawerItemCollection = (data: ContentfulContentNode[]) =>
  data.reduce<
    Pick<IAppContext, 'drawerItemCollectionList' | 'drawerItemCollection'>
  >(
    (acc, card: ContentfulContentNode) => {
      const cardProps = parseSingleDrawerItem(card)
      acc.drawerItemCollectionList.push(cardProps)
      acc.drawerItemCollection[card.sys.id] = cardProps
      return acc
    },
    { drawerItemCollectionList: [], drawerItemCollection: {} }
  )

export const parseSettingsItem = (data: ContentfulContentNode) => {
  return {
    mobileAppSettings: data,
  }
}

export const parseSingleDetail = (data: ContentfulContentNode) => {
  const {
    location,
    sys: { id },
    title,
    subtitle,
    contentMobile,
    contentHtml,
    mediaLabel,
    mediaType,
    mediaId,
    mediaImage,
    hasShare,
    shareLabelText,
    shareUrl,
  } = data
  return {
    location,
    id,
    title,
    subtitle,
    contentMobile,
    contentHtml,
    mediaLabel,
    mediaType,
    mediaId,
    hasShare,
    shareLabelText,
    shareUrl,
    mediaImage: {
      id: mediaImage?.sys?.id,
      url: mediaImage?.url,
      height: mediaImage?.height,
      width: mediaImage?.height,
      title: mediaImage?.title,
    },
  }
}

export const parseDetailPageCollection = (data: ContentfulContentNode[]) =>
  data.reduce<
    Pick<IAppContext, 'detailPageCollectionList' | 'detailPageCollection'>
  >(
    (acc, card: ContentfulContentNode) => {
      const cardProps = parseSingleDetail(card)
      acc.detailPageCollectionList.push(cardProps)
      acc.detailPageCollection[card.sys.id] = cardProps
      return acc
    },
    { detailPageCollectionList: [], detailPageCollection: {} }
  )
