import { useEffect, useMemo, useState, useCallback } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { ApolloProvider } from '@apollo/client'

import Login from '../Login/Login'
import Navbar from '../Navbar/Navbar'
import { createApolloClient } from '../../services/contentful/contentfulGraphql'
import QueryWrapper from '../QueryWrapper/QueryWrapper'
import { AppContext, IAppContext, initialAppContext } from './App.context'
import ContentfulApi, {
  ContentfulApiProps,
} from '../../services/contentful/contentfulApi'
import MainRoutes from '../Navigation/MainRoutes'
import FallbackRoutes from '../Navigation/FallbackRoutes'
import {
  getAllLocalStoredValues,
  deleteAllLocalStoredValues,
} from '../../utils/storage'
import Loader from '../Loader/Loader'

const appTitle = `ADM: CMS v${process.env.REACT_APP_VERSION}`

const App = () => {
  const [isLoading, setLoading] = useState(true)
  const [context, setContext] = useState<IAppContext>({
    ...initialAppContext,
    ...getAllLocalStoredValues(),
  })

  const handleSetContext = useCallback((vals: Partial<IAppContext>) => {
    setContext((c) => ({ ...c, ...vals }))
  }, [])

  /**
   * GraphQL is caching the query even with 'no-cache' or 'network-only' polices reloading the app is the cleanest and quickest way to show the update.
   */
  const handleUpdate = useCallback(() => {
    // eslint-disable-next-line no-restricted-globals
    location.reload()
  }, [])

  const handleLogoff = useCallback(() => {
    handleSetContext({ isAuthorized: false })
    deleteAllLocalStoredValues()
  }, [handleSetContext])

  useEffect(() => {
    if (
      context.contentfulSpaceId &&
      context.contentfulProductionToken &&
      context.vimeoAccessToken &&
      context.vimeoClientId &&
      context.vimeoClientSecret
    ) {
      handleSetContext({ isAuthorized: true })
    }

    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!context.onUpdate && !context.onLogoff) {
      setContext((c) => ({
        ...c,
        onUpdate: handleUpdate,
        onLogoff: handleLogoff,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.onLogoff, context.onUpdate])

  const initializeContentfulApi = (props: ContentfulApiProps) => {
    if (context.contentfulApiInstance) {
      return
    }

    const contentfulApiInstance = new ContentfulApi(props)

    handleSetContext({
      contentfulApiInstance,
    })
  }

  useEffect(() => {
    if (!context.isAuthorized) {
      return
    }

    const { contentfulSpaceId, contentfulPrivateToken } = context

    // Exit if we already set accesToken
    if (!contentfulSpaceId || !contentfulPrivateToken) {
      return
    }

    initializeContentfulApi({
      clientSpace: contentfulSpaceId,
      accessToken: contentfulPrivateToken,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    context.isAuthorized,
    context.contentfulSpaceId,
    context.contentfulPrivateToken,
  ])

  const apolloClient = useMemo(() => {
    if (
      !context.contentfulApiInstance ||
      !context.contentfulSpaceId ||
      !context.contentfulProductionToken
    ) {
      return null
    }

    return createApolloClient(
      context.contentfulSpaceId,
      context.contentfulProductionToken
    )
  }, [
    context.contentfulSpaceId,
    context.contentfulProductionToken,
    context.contentfulApiInstance,
  ])

  const isReady = useMemo(
    () => context.isAuthorized && context.contentfulApiInstance && apolloClient,
    [context.isAuthorized, context.contentfulApiInstance, apolloClient]
  )

  return (
    <div className='flex h-full w-full'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{appTitle}</title>
      </Helmet>
      <AppContext.Provider value={context}>
        {isLoading ? (
          <div className='flex items-center justify-center h-screen w-screen'>
            <Loader height={48} width={48} />
          </div>
        ) : (
          <Router>
            <FallbackRoutes>
              {!isReady && <Login setContext={handleSetContext} />}
            </FallbackRoutes>
            {isReady && (
              <ApolloProvider client={apolloClient}>
                <QueryWrapper setContext={handleSetContext}>
                  <Navbar />
                  <MainRoutes />
                </QueryWrapper>
              </ApolloProvider>
            )}
          </Router>
        )}
      </AppContext.Provider>
    </div>
  )
}

export default App
