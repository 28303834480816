interface CoordinatesGeocoderOutput {
  place_name: string
}

export const coordinatesGeocoder = (
  query: any
): CoordinatesGeocoderOutput[] | null => {
  // match anything which looks like a decimal degrees coordinate pair
  var matches = query.match(
    /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i
  )
  if (!matches) {
    return null
  }

  function coordinateFeature(lng: number, lat: number) {
    return {
      center: [lng, lat],
      geometry: {
        type: 'Point',
        coordinates: [lng, lat],
      },
      place_name: 'Lat: ' + lat + ' Lng: ' + lng,
      place_type: ['coordinate'],
      properties: {},
      type: 'Feature',
    }
  }

  const coord1 = Number(matches[1])
  const coord2 = Number(matches[2])
  const geocodes = []

  if (coord1 < -90 || coord1 > 90) {
    // must be lng, lat
    geocodes.push(coordinateFeature(coord1, coord2))
  }

  if (coord2 < -90 || coord2 > 90) {
    // must be lat, lng
    geocodes.push(coordinateFeature(coord2, coord1))
  }

  if (geocodes.length === 0) {
    // else could be either lng, lat or lat, lng
    geocodes.push(coordinateFeature(coord1, coord2))
    geocodes.push(coordinateFeature(coord2, coord1))
  }

  return geocodes
}

export const updateGeocoder = (
  geocoder: any,
  { lat, lng }: { lat: number; lng: number }
) => {
  const coordinateQuery = `${lat},${lng}`
  // Parses the coordinates
  const geoCoordinates = coordinatesGeocoder(coordinateQuery)

  if (!geoCoordinates || geoCoordinates.length === 0) {
    return
  }

  geocoder.setInput(geoCoordinates[0].place_name)
}
