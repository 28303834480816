import { FileMediaTypes } from '../Inputs/DragDropUpload'
import { FormVariant, State } from './FormPage.types'

interface ValidityReturn {
  isValid: boolean
  items: Record<string, boolean>
}

type StateAndUploadQueue = State & { fileUploadQueue: { current: any } }

export const validateFormItems = (
  variant: FormVariant,
  state: StateAndUploadQueue
): ValidityReturn => {
  switch (variant) {
    case FormVariant.DETAILS:
      return validateDetailFormItems(state)
    case FormVariant.DRAWER:
      return validateDrawerFormItems(state)
    case FormVariant.SETTINGS:
      return validateSettings(state)
    default:
      return {
        isValid: false,
        items: { noVariant: true },
      }
  }
}

export const validateSettings = ({
  mapOverlayTransparency,
  mapOverlaySizeRatio,
}: State) => {
  return {
    isValid: !!mapOverlaySizeRatio && !!mapOverlayTransparency,
    items: {
      mapOverlaySizeRatio: !!mapOverlaySizeRatio,
      mapOverlayTransparency: !!mapOverlayTransparency,
    },
  }
}

export const validateDrawerFormItems = ({
  title,
  mediaLabel,
  mediaImage,
  order,
}: StateAndUploadQueue): {
  isValid: boolean
  items: Record<string, boolean>
} => {
  const validationItems = {
    title,
    order,
  } as Record<string, any>

  if (mediaLabel && mediaImage) {
    validationItems.mediaLabel = mediaLabel
  }

  let isValid: boolean = true

  const validityObject = Object.entries(validationItems).reduce(
    (acc, [key, val]) => {
      if (val === undefined || val === null) {
        isValid = false
      }
      // all keys need to be string w/ data, true (booleans), or objects
      acc[key] = !!val
      return acc
    },
    {} as Record<string, boolean>
  )

  return {
    isValid,
    items: validityObject,
  }
}

/**
 *
 * subtitle, hasShare, shareLabel, contentHtml text can be empty
 */
export const validateDetailFormItems = ({
  title,
  location,
  mediaType,
  mediaLabel,
  mediaId,
  mediaImage,
  fileUploadQueue,
}: StateAndUploadQueue): {
  isValid: boolean
  items: Record<string, boolean>
} => {
  const hasPreviousMedia =
    mediaType && mediaType === FileMediaTypes.IMAGE
      ? !!mediaImage?.url
      : !!mediaId
  const hasMediaQueued = fileUploadQueue.current.has(mediaType)

  const validationItems: Record<string, any> = {
    title,
    location,
    hasValidMedia: false,
  }

  /**
   * hasValidMedia is truthy for the following cases
   * 1. if it has previous media information
   * 2. if it has media queued
   * 3. if it has no media queued and no previous media information (allowing for media to not be submitted for some locations)
   */
  if (mediaType && hasPreviousMedia) {
    validationItems.hasValidMedia = true
  }

  if (!hasMediaQueued && !mediaId && !mediaImage?.url && !mediaLabel) {
    validationItems.hasValidMedia = true
  }

  if (hasMediaQueued) {
    validationItems.hasValidMedia = true
  }

  // check if mediaLabel is not empty when mediaImage or mediaId is provided or mediaFiles are queued
  if (!mediaLabel && (!!mediaId || mediaImage?.url || hasMediaQueued)) {
    validationItems.mediaLabel = false
  }

  let isValid: boolean = true

  /**
   * Parses through validation items and changes isValid to false if any items do not match
   */
  const validityObject = Object.entries(validationItems).reduce(
    (acc, [key, val]) => {
      if (!val) {
        isValid = false
      }
      // all keys need to be string w/ data, true (booleans), or objects
      acc[key] = !!val
      return acc
    },
    {} as Record<string, boolean>
  )

  return {
    isValid,
    items: validityObject,
  }
}

export const selectDetailsState = ({
  title,
  subtitle,
  contentHtml,
  mediaType,
  mediaLabel,
  location,
  mediaId,
  hasShare,
  shareLabelText,
  shareUrl,
}: State) => {
  return {
    title,
    subtitle,
    contentHtml,
    mediaType,
    mediaLabel,
    location,
    mediaId,
    hasShare,
    shareLabelText,
    shareUrl,
  }
}

export const selectDrawerState = ({
  title,
  contentHtml,
  mediaLabel,
  order,
  mediaImage,
}: State) => {
  return {
    title,
    contentHtml,
    mediaLabel,
    mediaImage,
    order: order && parseInt(order, 10),
  }
}

export const selectSettingState = ({
  mapOverlayTransparency,
  mapOverlaySizeRatio,
}: State) => ({
  mapOverlayTransparency: parseFloat(mapOverlayTransparency), // must be a float on contentful
  mapOverlaySizeRatio: parseFloat(mapOverlaySizeRatio), // must be a float on contentful
})

export const filterStateByVariant = (variant: FormVariant, state: State) => {
  switch (variant) {
    case FormVariant.DETAILS:
      return selectDetailsState(state)
    case FormVariant.DRAWER:
      return selectDrawerState(state)
    case FormVariant.SETTINGS:
      return selectSettingState(state)
  }
}
