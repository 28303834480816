import SecureLS from 'secure-ls'

export const secureLocalStorage = new SecureLS({ isCompression: false })

export const getAllLocalStoredValues = () =>
  secureLocalStorage
    .getAllKeys()
    .reduce((acc: Record<string, any>, key: string) => {
      acc[key] = secureLocalStorage.get(key)
      return acc
    }, {})

export const deleteAllLocalStoredValues = () => {
  secureLocalStorage.removeAll()
}
