import { useEffect, FC, memo } from 'react'
import { useQuery } from '@apollo/client'

import {
  combinedQuery,
  settingsNodeId,
} from '../../services/contentful/contentfulGraphql'
import {
  parseDetailPageCollection,
  parseDrawerItemCollection,
  parseSettingsItem,
} from '../../utils/helpers'
import { NetworkStatus } from '@apollo/client'

interface QueryWrapperProps {
  children: any
  setContext: (data: any) => void
}

const QueryWrapper: FC<QueryWrapperProps> = ({ children, setContext }) => {
  const { loading, data, error, networkStatus } = useQuery(combinedQuery, {
    variables: {
      settingsNodeId: settingsNodeId,
    },
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    if (!loading && Array.isArray(data?.detailPageCollection?.items)) {
      const {
        detailPageCollectionList,
        detailPageCollection,
      } = parseDetailPageCollection(data.detailPageCollection.items)

      const drawerData = parseDrawerItemCollection(
        data.drawerItemCollection.items
      )

      const settings = parseSettingsItem(data.mobileAppSettings)

      setContext({
        ...drawerData,
        ...settings,
        detailPageCollectionList,
        detailPageCollection,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data, networkStatus])

  if (networkStatus === NetworkStatus.refetch) return 'Refetching!'

  return children
}

export default memo(QueryWrapper)
