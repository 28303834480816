import { FC } from 'react'
import { Switch, Route } from 'react-router-dom'

interface FallbackRoutesProps {
  children: any
}

/**
 * Must be wrapped in Router, used for fallback Routing
 */
const FallbackRoutes: FC<FallbackRoutesProps> = ({ children }) => (
  <Switch>
    <Route path='/' render={() => children} />
  </Switch>
)

export default FallbackRoutes
