import { PageActionVariant } from '../../types/sharedTypes'
import { RouteComponentProps } from 'react-router-dom'
import { TextInputProps } from '../Inputs/TextInput'
import { RichTextInputProps } from '../Inputs/RichTextInput'
import { RadioInputProps } from '../Inputs/RadioInput'
import { FileMediaTypes, DragDropUploadProps } from '../Inputs/DragDropUpload'

export enum InputTypes {
  TEXT_INPUT = 'TEXT_INPUT',
  RICH_TEXT_INPUT = 'RICH_TEXT_INPUT',
  RADIO_INPUT = 'RADIO_INPUT',
  FILE_UPLOAD = 'FILE_UPLOAD',
}

export interface DetailContentNode {
  title: string
  subtitle: string
  location?: {
    lat: number
    lon: number
  }
  contentHtml: string
  contentMobile: Record<string, any>[]
  mediaLabel: string
  mediaType: FileMediaTypes
  mediaId: string
  mediaImage: {
    sys: {
      id: string
    }
    url: string
    height: number
    width: number
    title: string
  } | null
  hasShare: boolean
  shareLabelText?: string
  shareUrl?: string
}

interface DrawerContentNode extends DetailContentNode {
  order?: string | null
}

interface SettingsContentNode {
  mapOverlayTransparency: string
  mapOverlaySizeRatio: string
}

export type FormPageContentNode = DetailContentNode &
  DrawerContentNode &
  SettingsContentNode

export enum FormVariant {
  DETAILS = 'details',
  DRAWER = 'drawer',
  SETTINGS = 'settings',
}

export interface FormPageProps extends Pick<RouteComponentProps, 'history'> {
  id: string
  pageAction: PageActionVariant
  variant: FormVariant
}

export interface RadioInputType
  extends Omit<RadioInputProps<FileMediaTypes>, 'onChange'> {
  type: InputTypes.RADIO_INPUT
}

export interface BaseFieldType {
  title: string
  placeholder: string
}

export interface RichTextInputType
  extends Omit<RichTextInputProps, 'onChange'> {
  type: InputTypes.RICH_TEXT_INPUT
}

export interface TextInputType extends Omit<TextInputProps, 'onChange'> {
  type: InputTypes.TEXT_INPUT
}

export interface UploadInputType extends Omit<DragDropUploadProps, 'onChange'> {
  type: InputTypes.FILE_UPLOAD
}

export type FieldTypes =
  | RichTextInputType
  | RadioInputType
  | TextInputType
  | UploadInputType

export type State = FormPageContentNode
