import { ContentType, ContentTypes } from './react-native-markup.types'

export const formatEmailLink = (email: string) => `mailto:${email}`

enum TagTypes {
  HTMLParagraphElement = 'P',
  HTMLAnchorElement = 'A',
  HTMLBRElement = 'BR',
  TEXT = '#text',
  LI = 'LI',
  UL = 'UL',
  ITALIC = 'EM',
  BOLD = 'STRONG',
  HEADLINE_ONE = 'H1',
  SPAN = 'SPAN',
}

export const parseHtmlToMobileMarkup = (htmlString: string) => {
  const parser = new DOMParser()
  const getter = parser.parseFromString(htmlString, 'text/html').body

  // @ts-ignore
  const bodyNode = [...getter.children]

  const mobileNodes: ContentTypes[] = []

  const handleParseElements = (
    element: any,
    isChild: boolean = false,
    parentElement: any | null = null
  ) => {
    let mobileNode = {} as ContentTypes
    let styleOverride = {} as Record<string, any>

    let children: ContentTypes[] = []

    if (element.childElementCount > 0) {
      element.childNodes.forEach((childElement: any) => {
        children.push(
          handleParseElements(childElement, true, element) as ContentTypes
        )
      })
    }

    switch (element.nodeName) {
      case TagTypes.HEADLINE_ONE:
        let text = element.innerHTML

        if (children.length === 1) {
          if (children[0].type === ContentType.LINEBREAK) {
            mobileNode = children[0]
            break
          }

          if (
            children[0].type === ContentType.TEXT ||
            children[0].type === ContentType.TEXT_BOLD ||
            children[0].type === ContentType.TEXT_ITALIC ||
            children[0].type === ContentType.TEXT_ITALIC_BOLD
          ) {
            text = children[0].text
            styleOverride = children[0].styleOverride || {}
          }
        }

        mobileNode = {
          type: ContentType.HEADLINE_ONE,
          text,
        }
        break
      case TagTypes.BOLD:
        mobileNode = {
          type: ContentType.TEXT_BOLD,
          text: element.innerHTML,
        }

        if (parentElement?.nodeName === TagTypes.ITALIC) {
          mobileNode.type = ContentType.TEXT_ITALIC_BOLD
        }
        break
      case TagTypes.ITALIC:
        mobileNode = {
          type: ContentType.TEXT_ITALIC,
          text: element.innerHTML,
        }

        if (parentElement?.nodeName === TagTypes.BOLD) {
          mobileNode.type = ContentType.TEXT_ITALIC_BOLD
        }
        break
      case TagTypes.TEXT:
        mobileNode = {
          type: ContentType.TEXT,
          text: element.nodeValue,
        }
        break
      case TagTypes.SPAN:
        if (element.style) {
          if (element.childElementCount === 0 && element.innerHTML.length > 0) {
            mobileNode = {
              type: ContentType.TEXT,
              text: element.innerHTML,
            }
          }
        }
        break
      case TagTypes.HTMLParagraphElement:
        const parseString = element.innerHTML.replace(/&nbsp;/g, ' ')
        if (children.length === 0) {
          mobileNode = {
            type: ContentType.TEXT,
            text: parseString,
          }
        }
        break
      case TagTypes.HTMLBRElement:
        mobileNode = {
          type: ContentType.LINEBREAK,
        }
        break
      case TagTypes.HTMLAnchorElement:
        mobileNode = {
          type: ContentType.LINK,
          text: element.innerHTML,
          url: element.href,
        }
        break
      case TagTypes.UL:
        // Top level nodes will return
        mobileNode = {
          type: ContentType.UNORDERED_LIST,
          list: children,
        }
        break
      case TagTypes.LI:
        // Will turn into a textblock or a text node
        if (element.childElementCount === 0) {
          mobileNode = { type: ContentType.TEXT, text: element.innerHTML }
        }
        break
      default:
        console.error(
          'error this element is not supported',
          element,
          element.nodeName
        )
    }

    // Check for inline styles on tags and apply them
    if (element.style) {
      if (element.style.length > 0) {
        for (let i = 0; i < element.style.length; i++) {
          const selectedStyle = element.style[i]
          // Only allow color overrides
          if (selectedStyle === 'color') {
            styleOverride.color = element.style[selectedStyle]
          }
        }
      }
    }

    /**
     * Multiple child nodes require a textblock
     * Having this override outside the switch statement allows us to disregard elements with children in them and use the textblock
     * textblock can have style overrides
     */
    if (children.length > 1 && element.nodeName !== TagTypes.UL) {
      mobileNode = {
        type: ContentType.TEXT_BLOCK,
        nodes: children,
      }

      // If the headline has multiple children IE a textblock add a headline style override to textblock
      if (element.nodeName === TagTypes.HEADLINE_ONE) {
        styleOverride.headline = ContentType.HEADLINE_ONE
      }

      if (
        element.nodeName === TagTypes.BOLD ||
        element.nodeName === TagTypes.ITALIC
      ) {
        styleOverride.textStyle =
          element.nodeName === TagTypes.BOLD
            ? ContentType.TEXT_BOLD
            : ContentType.TEXT_ITALIC
      }
    } else if (
      children.length === 1 &&
      element.nodeName !== TagTypes.HEADLINE_ONE
    ) {
      mobileNode = children[0]
    }

    if (Object.keys(styleOverride).length > 0) {
      mobileNode.styleOverride = styleOverride
      // join the style override objects just in case
    }

    if (isChild) {
      return mobileNode
    }

    if (mobileNode.type && !isChild) {
      mobileNodes.push(mobileNode)
    }
  }
  bodyNode.forEach((element: any) => {
    handleParseElements(element, false, null)
  })
  return mobileNodes
}

/**
 * Mostly for handling text node cases
 */
export const parseMobileMarkupToHtml = (elements: ContentTypes[]) => {
  const elementsString = elements.reduce((acc: string, ele: ContentTypes) => {
    switch (ele.type) {
      case ContentType.TEXT:
        acc += `<p>${ele.text}</p><br>`
        break
      case ContentType.TEXT_BOLD:
      case ContentType.TEXT_ITALIC:
      case ContentType.HEADLINE_ONE:
      case ContentType.TEXT_BLOCK:
      case ContentType.LINK:
      case ContentType.SHARE:
      case ContentType.UNORDERED_LIST:
      case ContentType.VIDEO:
      case ContentType.IMAGE:
    }

    return acc
  }, '')

  return elementsString
}
