import { FC } from 'react'
import { Switch, Route } from 'react-router-dom'

import Home from '../Home/Home'
import DetailsHome from '../DetailsHome/DetailsHome'
import DrawerItemsHome from '../Pages/DrawerItemsHome/DrawerItemsHome'
import { PageActionVariant } from '../../types/sharedTypes'
import FormPage from '../FormPage/FormPage'
import { FormVariant } from '../FormPage/FormPage.types'
import { settingsNodeId } from '../../services/contentful/contentfulGraphql'

/**
 * Must be wrapped in Router
 * Used for all the app routes after authorization is successful
 */
const MainRoutes: FC = () => (
  <Switch>
    <Route
      path='/:basePath/page/:id/:pageAction'
      render={(props: any) => (
        <FormPage
          history={props.history}
          variant={props.match.params.basePath}
          id={props.match.params.id}
          pageAction={props.match.params.pageAction as PageActionVariant}
        />
      )}
    />
    <Route
      path='/:basePath/page/:pageAction'
      render={(props: any) => (
        <FormPage
          history={props.history}
          variant={props.match.params.basePath}
          id={props.match.params.pageAction}
          pageAction={props.match.params.pageAction as PageActionVariant}
        />
      )}
    />
    <Route path='/details' component={DetailsHome} />
    <Route path='/drawer' component={DrawerItemsHome} />
    <Route
      path='/settings/:pageAction'
      render={(props: any) => (
        <FormPage
          id={settingsNodeId}
          history={props.history}
          pageAction={props.match.params.pageAction}
          variant={FormVariant.SETTINGS}
        />
      )}
    />
    <Route path='/' component={Home} />
  </Switch>
)

export default MainRoutes
