import axios from 'axios'

import { getReplaceUrl, vimeoBaseUrl } from './vimeo.helpers'

interface Video {
  fileName: string
  size: string | number
  upload: ArrayBuffer
}

type OnProgress = (progress: any) => void

export class VimeoApi {
  client: any

  accessToken: string

  constructor(vimeoAccessToken: string) {
    this.accessToken = vimeoAccessToken
  }

  uploadVideo = async (
    uploadLink: string,
    video: Video,
    onProgress: OnProgress
  ) => {
    try {
      await axios.patch(uploadLink, video.upload, {
        onUploadProgress: onProgress,
        headers: {
          'Tus-Resumable': '1.0.0',
          'Upload-Offset': '0',
          'Content-Type': 'application/offset+octet-stream',
          Accept: 'application/vnd.vimeo.*+json;version=3.4',
        },
      })
      return true
    } catch (e) {
      console.log('error with upload', e)
      return false
    }
  }

  createVideo = async (video: Video, onProgress: OnProgress) => {
    const videoUrl = 'https://api.vimeo.com/me/videos'

    let uploadResponse: any

    try {
      uploadResponse = await axios.post(
        videoUrl,
        {
          upload: {
            approach: 'tus',
            size: `${video.size}`,
          },
          name: video.fileName,
          privacy: {
            view: 'unlisted',
            download: false,
            embed: 'public',
          },
        },
        {
          headers: {
            Authorization: `bearer ${this.accessToken}`,
            'Content-Type': 'application/json',
            Accept: 'application/vnd.vimeo.*+json;version=3.4',
          },
        }
      )
    } catch (e) {
      console.log('error with upload', e)
    }

    if (!uploadResponse?.data?.upload?.upload_link) {
      console.log('no upload link found')
      return
    }

    await this.uploadVideo(
      uploadResponse.data.upload.upload_link,
      video,
      onProgress
    )

    return uploadResponse.data
  }

  replaceVideo = async (
    videoId: string,
    newVideo: Video,
    onProgress: OnProgress
  ) => {
    const url = getReplaceUrl(videoId)
    let uploadResponse: any

    try {
      uploadResponse = await axios.post(
        url,
        {
          file_name: newVideo.fileName,
          upload: {
            status: 'in_progress',
            size: `${newVideo.size}`,
            approach: 'tus',
          },
        },
        {
          headers: {
            Authorization: `bearer ${this.accessToken}`,
            'Content-Type': 'application/json',
            Accept: 'application/vnd.vimeo.*+json;version=3.4',
          },
        }
      )
    } catch (e) {
      console.log('error with upload', e)
    }

    if (!uploadResponse?.data?.upload?.upload_link) {
      console.log('no upload link found')
      return
    }

    await this.uploadVideo(
      uploadResponse.data.upload.upload_link,
      newVideo,
      onProgress
    )

    return uploadResponse.data
  }

  deleteVideo = async (videoId: string) => {
    console.log('deletVideo')
    try {
      const response = await axios.delete(`${vimeoBaseUrl}/${videoId}`, {
        headers: {
          Authorization: `bearer ${this.accessToken}`,
        },
      })
      return response.status === 204
    } catch (e) {
      console.error(`error deleting video videoId: ${videoId}`, e)
    }

    return false
  }
}
