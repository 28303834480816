import {
  FC,
  useRef,
  SyntheticEvent,
  useEffect,
  useContext,
  useMemo,
  useState,
  useCallback,
} from 'react'
import classNames from 'classnames'
import deepEqual from 'deep-equal'

import TextInput, { TextInputValueType } from '../Inputs/TextInput'
import RadioInput from '../Inputs/RadioInput'
import DragDropUpload, { FileMediaTypes } from '../Inputs/DragDropUpload'
import GeocoderMap, { MapVariant } from '../Map/Map'
import { AppContext } from '../App/App.context'
import RichTextInput from '../Inputs/RichTextInput'
import Loader, { LoaderVariant } from '../Loader/Loader'
import {
  ContentfulContentId,
  FileData,
} from '../../services/contentful/contentfulApi'
import {
  makeCancelable,
  isNullOrUndefined,
  navigateToPage,
} from '../../utils/helpers'
import { Button, ButtonIconVariant, ButtonVariant } from '../Button/Button'
import { PageActionVariant } from '../../types/sharedTypes'
import Checkbox from '../Inputs/Checkbox'
import { VimeoApi } from '../../services/vimeo/vimeoApi'
import { selectMediaIdFromUri } from '../../services/vimeo/vimeo.helpers'

import { validateFormItems, filterStateByVariant } from './FormPage.helpers'
import { State, FormPageProps, FormVariant } from './FormPage.types'
import { parseHtmlToMobileMarkup } from '../../utils/react-native-markup'

const radioItems = [
  {
    value: FileMediaTypes.IMAGE,
    label: 'Image',
  },
  {
    value: FileMediaTypes.VIDEO,
    label: 'Video',
  },
]

const mediaLabelBaseProps = {
  label: 'Caption',
  description: 'Caption for your media file and user accessibility',
  placeholder: 'add your caption here',
}

export const baseState = {
  title: '',
  subtitle: '',
  contentHtml: '',
  contentMobile: [],
  mediaType: FileMediaTypes.IMAGE,
  mediaLabel: '',
  location: undefined,
  mediaId: '',
  mediaImage: null,
  hasShare: true,
  shareLabelText: 'Share this article',
  shareUrl: '',
  order: null,
  mapOverlaySizeRatio: '0.5',
  mapOverlayTransparency: '0.5',
}

const FormPage: FC<FormPageProps> = ({ variant, history, id, pageAction }) => {
  const context = useContext(AppContext)
  const [hasDeleteConfirm, setDeleteConfirm] = useState(false)

  const [hasUploadQueued, setUploadQueued] = useState(false)
  const [isDeleting, setDeleting] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)
  const [uploadProgress, setProgress] = useState('0%')
  const [state, setState] = useState<State>(baseState)

  const { isDetailPage, isDrawerPage, isSettingsPage } = useMemo(
    () => ({
      isDetailPage: variant === FormVariant.DETAILS,
      isDrawerPage: variant === FormVariant.DRAWER,
      isSettingsPage: variant === FormVariant.SETTINGS,
    }),
    [variant]
  )

  const pageTitle = useMemo(() => `${variant} Page`, [variant])

  const deleteText = useMemo(
    () => (hasDeleteConfirm ? 'Confirm Page Delete' : 'Delete Page'),
    [hasDeleteConfirm]
  )

  const fileUploadQueue = useRef<Map<FileMediaTypes, any>>(new Map())
  const promises = useRef<Map<string, any>>(new Map())

  const selectedCollection = useCallback(() => {
    if (isDetailPage && id) {
      return context.detailPageCollection[id]
    }

    if (isDrawerPage && id) {
      return context.drawerItemCollection[id]
    }

    if (isSettingsPage) {
      return context.mobileAppSettings
    }

    return {}
  }, [isDetailPage, isDrawerPage, id, context, isSettingsPage])

  useEffect(() => {
    if (isDetailPage) {
      setState((s) => ({ ...s, hasShare: true }))
    } else {
      setState((s) => ({ ...s, hasShare: false }))
    }
    return function cancel() {
      promises.current.forEach((p) => p.cancel())
      promises.current = new Map()
    }
  }, [isDetailPage])

  useEffect(() => {
    if (id !== PageActionVariant.ADD) {
      const formData = selectedCollection()

      if (!formData) {
        console.error('page selector failed')
      }

      setState((s) => ({
        ...s,
        ...formData,
      }))
    }
  }, [selectedCollection, id])

  const computedMediaUrl = useMemo(() => {
    if (!selectedCollection()) {
      return null
    }

    if (state.mediaType === FileMediaTypes.IMAGE || isDrawerPage) {
      return selectedCollection()?.mediaImage?.url
    }

    return selectedCollection()?.mediaId
  }, [selectedCollection, isDrawerPage, state])

  const canSubmit = useMemo<{
    isValid: boolean
    isViewOnly?: boolean
    isDeepEqual: boolean | null
    items: Record<string, boolean>
  }>(() => {
    /**
     * Applicable for 'edit pages
     */
    let isDeepEqual: boolean | null = null

    /**
     * view pages should allow user to click the button and navigate to edit
     */
    if (pageAction === PageActionVariant.VIEW) {
      return {
        isValid: true,
        isViewOnly: true,
        items: {} as Record<string, boolean>,
        isDeepEqual,
      }
    }

    if (selectedCollection() && !hasUploadQueued) {
      isDeepEqual = deepEqual(
        filterStateByVariant(variant, selectedCollection()),
        filterStateByVariant(variant, state)
      )
    }

    if (isDeepEqual) {
      return {
        isValid: false,
        isDeepEqual,
        items: {} as Record<string, boolean>,
      }
    }

    /**
     * Applicable for 'add' pages
     */
    const { isValid, items } = validateFormItems(variant, {
      ...state,
      fileUploadQueue,
    })

    return {
      isValid,
      isDeepEqual,
      items,
    }
  }, [pageAction, selectedCollection, state, hasUploadQueued, variant])

  const primaryButtonProps = useMemo(() => {
    switch (pageAction.toLowerCase()) {
      case PageActionVariant.ADD:
        return {
          text: 'Create',
          title: 'Creates a new page.',
          variant: ButtonVariant.PRIMARY,
          iconVariant: ButtonIconVariant.SAVE,
        }
      case PageActionVariant.EDIT:
        return {
          text: 'Save',
          title: 'Saves your changes when highlighted.',
          variant: ButtonVariant.PRIMARY,
          iconVariant: ButtonIconVariant.SAVE,
        }
      case PageActionVariant.VIEW:
      default:
        return {
          text: 'Edit',
          title: 'Click here to enter edit mode',
          variant: ButtonVariant.PRIMARY,
          iconVariant: ButtonIconVariant.EDIT,
        }
    }
  }, [pageAction])

  const primaryButtonTitle = useMemo(() => {
    if (canSubmit.isValid) {
      return primaryButtonProps.title
    }

    if (canSubmit.isDeepEqual) {
      return 'Saving disabled, content is the same'
    }

    if (canSubmit.items) {
      return 'Saving disabled, content items are not valid'
    }
  }, [canSubmit, primaryButtonProps])

  const handleProgress = (progress: ProgressEvent) => {
    const { loaded, total } = progress

    // PercentageProgress
    setProgress(`${Math.round((loaded / total) * 100)}%`)
  }

  const handleDelete = async (e: SyntheticEvent) => {
    e.preventDefault()
    if (!hasDeleteConfirm) {
      setDeleteConfirm(true)
      return
    }

    setDeleting(true)

    if (!context.contentfulSpaceId || !context.contentfulPrivateToken) {
      return
    }

    if (
      isDetailPage &&
      selectedCollection().mediaId &&
      context.vimeoAccessToken
    ) {
      const vimeo = new VimeoApi(context.vimeoAccessToken)
      await vimeo.deleteVideo(selectedCollection().mediaId)
    }

    await context.contentfulApiInstance.deleteEntry(id)

    setDeleting(false)

    history.push(`/${variant}`)

    if (context.onUpdate) {
      context.onUpdate()
    }
  }

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault()

    if (!canSubmit.isValid) {
      return
    }

    if (pageAction === PageActionVariant.VIEW) {
      if (isSettingsPage) {
        history.push('/settings/edit')
      } else {
        navigateToPage(history, variant, id)(PageActionVariant.EDIT)
      }

      return
    }

    const { isValid } = validateFormItems(variant, {
      ...state,
      fileUploadQueue,
    })

    if (!isValid || !context.contentfulApiInstance) {
      return
    }

    setSubmitting(true)

    const { contentfulApiInstance } = context

    let promise: Promise<any> | null = null

    let mediaId: string | undefined = undefined

    if (
      fileUploadQueue.current.has(FileMediaTypes.VIDEO) &&
      context.vimeoAccessToken
    ) {
      const vimeo = new VimeoApi(context.vimeoAccessToken)

      const video = fileUploadQueue.current.get(FileMediaTypes.VIDEO)

      if (
        pageAction === PageActionVariant.EDIT &&
        selectedCollection().mediaId
      ) {
        await vimeo.replaceVideo(
          selectedCollection().mediaId, // mediaId needs to be a videoId
          video,
          handleProgress
        )

        // If the video was the only update return early
        const isDeepEqual = deepEqual(
          filterStateByVariant(variant, selectedCollection()),
          filterStateByVariant(variant, state)
        )

        if (isDeepEqual) {
          fileUploadQueue.current.delete(FileMediaTypes.VIDEO)
          setSubmitting(false)
          context.onUpdate && context.onUpdate()
          return
        }
      } else {
        const data = await vimeo.createVideo(video, handleProgress)
        mediaId = selectMediaIdFromUri(data.uri)
      }
      fileUploadQueue.current.delete(FileMediaTypes.VIDEO)
    }

    const selectedState = filterStateByVariant(variant, state)

    const contentfulData = selectedState as Partial<State> & {
      uploadMediaImage: any
    }

    if (!isSettingsPage) {
      contentfulData.uploadMediaImage =
        fileUploadQueue.current.has(FileMediaTypes.IMAGE) &&
        fileUploadQueue.current.get(FileMediaTypes.IMAGE)
      contentfulData.contentMobile = parseHtmlToMobileMarkup(state.contentHtml)
    }

    if (mediaId && isDetailPage) {
      contentfulData.mediaId = mediaId
    }

    if (pageAction === PageActionVariant.EDIT) {
      promise = contentfulApiInstance.updateContentPage(id, contentfulData)
    }

    if (pageAction === PageActionVariant.ADD) {
      // The useEffect hook will toggle loading states based on this promise
      promise = contentfulApiInstance.createContentPage(
        contentfulData,
        isDetailPage
          ? ContentfulContentId.DETAIL_PAGE
          : ContentfulContentId.DRAWER_ITEM
      )
    }

    /**
     * Cancelable promises avoid potential bugs such as unmounting the component while waiting for data and firing off a stateful action after unmount
     */
    if (promise) {
      const promiseName = `detailPage-${pageAction}`
      const cancelablePromise = makeCancelable(promise)

      if (promises.current.has(promiseName)) {
        promises.current.delete(promiseName)
      }

      promises.current.set(promiseName, cancelablePromise)

      cancelablePromise.promise.then(async (data) => {
        if (fileUploadQueue.current.has(FileMediaTypes.IMAGE)) {
          fileUploadQueue.current.delete(FileMediaTypes.IMAGE)
        }
        if (fileUploadQueue.current.has(FileMediaTypes.VIDEO)) {
          fileUploadQueue.current.delete(FileMediaTypes.VIDEO)
        }

        promises.current.delete(promiseName)
        setSubmitting(false)
        setProgress('0%')

        /**
         * Refetches graphql data and navigates user to the newly created/updated page
         */
        if (!!context.onUpdate && data?.sys?.id) {
          if (isSettingsPage) {
            history.push('/settings/view')
          } else {
            navigateToPage(
              history,
              variant,
              data.sys.id
            )(PageActionVariant.VIEW)
          }
          context.onUpdate()
        }
      })
    }
  }

  const handleTextChange = (fieldId: string) => (
    e: InputEvent & { target: HTMLInputElement }
  ) => {
    e.preventDefault()

    if (e.target.value === null || e.target.value === undefined) {
      return
    }

    setState((s) => ({ ...s, [fieldId]: e.target.value }))
  }

  const handleRadioChange = (val: FileMediaTypes) =>
    setState((s) => ({
      ...s,
      mediaType: val,
    }))

  /**
   * Allow for file uploads to vimeo or contentful
   * uploads are put into a Map queue to preserve previous content if applicable
   */
  const handleFileChange = (fileData: FileData | null) => {
    if (fileData?.contentType?.match('image/')) {
      const replacementId = selectedCollection()?.mediaImage?.id
      if (replacementId) {
        fileData.replacementId = replacementId
      }
      // Image files are uploaded to contentful
      fileUploadQueue.current.set(FileMediaTypes.IMAGE, fileData)
    }

    if (fileData?.contentType?.match('video/')) {
      fileUploadQueue.current.set(FileMediaTypes.VIDEO, fileData)
    }
    setUploadQueued(true)

    if (!fileData) {
      fileUploadQueue.current.delete(state.mediaType)
      setUploadQueued(false)
      setState((s) => ({
        ...s,
        mediaImage: selectedCollection()?.mediaImage,
        mediaId: selectedCollection()?.mediaId,
      }))
    }
    // Video files are uploaded to vimeo
  }

  const handleCoordinates = (coordinates: { lat: number; lon: number }) => {
    setState((s) => ({ ...s, location: coordinates }))
  }

  const handleRichTextChange = (textNodes: any) => {
    setState((s) => ({ ...s, contentHtml: textNodes }))
  }

  const handleToggleshare = () => {
    setState((s) => ({ ...s, hasShare: !s.hasShare }))
  }

  const handleResetMapCoordinates = useCallback(() => {
    const { location } = context.detailPageCollection[id]
    setState((s) => ({ ...s, location }))
  }, [context.detailPageCollection, id])

  const hasInputsDisabled = useMemo(
    () => pageAction === PageActionVariant.VIEW,
    [pageAction]
  )

  const renderSettingsPage = () => {
    const rangeProps = {
      min: '0',
      max: '1.0',
      step: '0.05',
    }
    return (
      <>
        <TextInput
          {...rangeProps}
          label='Map overlay size ratio'
          onChange={handleTextChange('mapOverlaySizeRatio')}
          value={`${state.mapOverlaySizeRatio}`}
          valueType={TextInputValueType.RANGE}
          disabled={hasInputsDisabled}
        />
        <TextInput
          {...rangeProps}
          label='Map overlay transparency'
          onChange={handleTextChange('mapOverlayTransparency')}
          value={`${state.mapOverlayTransparency}`}
          valueType={TextInputValueType.RANGE}
          disabled={hasInputsDisabled}
        />
      </>
    )
  }

  /**
   * valid when showValidity is disabled
   * valid when validityState[field] is true
   */
  const isInvalidField = (fieldId: string) => {
    return canSubmit.items[fieldId] === false
  }

  return (
    <div className={classNames('w-full py-20 md:px-0 lg:px-6')}>
      {isSubmitting ? (
        <div className='flex items-center justify-center'>
          <div className='relative items-center justify-center'>
            <Loader height={200} width={200} />
            <div className='absolute inset-0 items-center flex justify-center mx-auto flex-col'>
              <div>Uploading</div>
              {uploadProgress !== '0%' && <div>{uploadProgress}</div>}
            </div>
          </div>
        </div>
      ) : id ? (
        <form
          className='shadow-lg border-gray-200 border-2	 sm:rounded-md md:p-6'
          onSubmit={handleSubmit}
        >
          <div className='flex justify-between pb-4 border-b-2 border-gray-200'>
            <h2 className='font-bold text-3xl capitalize'>{pageTitle}</h2>
            <div className='flex h-12'>
              <Button
                {...primaryButtonProps}
                title={primaryButtonTitle}
                hasIcon
                type='submit'
                disabled={!canSubmit.isValid || isDeleting}
              >
                {primaryButtonProps.text}
              </Button>
              {pageAction === PageActionVariant.EDIT && id && !isSettingsPage && (
                <Button
                  className='ml-4'
                  type='button'
                  variant={ButtonVariant.RED_CAUTION}
                  onClick={handleDelete}
                  title='Caution this will delete the entire post'
                  hasIcon
                  iconVariant={ButtonIconVariant.DELETE}
                  disabled={isDeleting}
                >
                  {!isDeleting ? (
                    deleteText
                  ) : (
                    <>
                      Removing
                      <Loader
                        className='mx-2'
                        color='white'
                        width={18}
                        height={18}
                      />
                    </>
                  )}
                </Button>
              )}
            </div>
          </div>
          <div
            className={classNames({
              'grid mt-4 lg:grid-cols-2 lg:gap-6': isDetailPage,
            })}
          >
            <div className='lg:col-span-1 sm:col-span-3'>
              {isSettingsPage ? (
                renderSettingsPage()
              ) : (
                <div className='sm:overflow-scroll'>
                  <div className='px-4 py-5 space-y-6 sm:p-6'>
                    <TextInput
                      key={'title'}
                      label='Title'
                      value={state.title}
                      disabled={hasInputsDisabled}
                      onChange={handleTextChange('title')}
                      hasError={isInvalidField('title')}
                    />
                    {isDetailPage && (
                      <TextInput
                        label='Author'
                        onChange={handleTextChange('subtitle')}
                        value={state.subtitle}
                        disabled={hasInputsDisabled}
                      />
                    )}
                    {isDetailPage && (
                      <RadioInput
                        label='Media Type'
                        items={radioItems}
                        selectedOption={state.mediaType}
                        onChange={handleRadioChange}
                        disabled={hasInputsDisabled}
                      />
                    )}

                    {isDrawerPage && (
                      <TextInput
                        label='Order'
                        onChange={handleTextChange('order')}
                        value={
                          isNullOrUndefined(state.order)
                            ? '1'
                            : (state.order as any)
                        }
                        valueType={TextInputValueType.NUMBER}
                        disabled={hasInputsDisabled}
                      />
                    )}
                    <TextInput
                      {...mediaLabelBaseProps}
                      onChange={handleTextChange('mediaLabel')}
                      value={state.mediaLabel}
                      disabled={hasInputsDisabled}
                      hasError={isInvalidField('mediaLabel')}
                    />
                    <DragDropUpload
                      url={computedMediaUrl}
                      label={
                        state.mediaType === FileMediaTypes.IMAGE
                          ? 'Media Image'
                          : 'Media Video'
                      }
                      mediaId={state.mediaId}
                      mediaLabel={state.mediaLabel}
                      disabled={hasInputsDisabled}
                      mediaType={state.mediaType}
                      onChange={handleFileChange}
                      hasError={isInvalidField('hasValidMedia')}
                    />
                    {(state.mediaType === FileMediaTypes.IMAGE ||
                      isDrawerPage) && (
                      <RichTextInput
                        label='Content Text'
                        onChange={handleRichTextChange}
                        value={state.contentHtml}
                        disabled={pageAction === PageActionVariant.VIEW}
                      />
                    )}

                    {isDetailPage && (
                      <Checkbox
                        key={'shareLabelText'}
                        label='Article Sharing'
                        onChange={handleToggleshare}
                        value={state.hasShare}
                        disabled={hasInputsDisabled}
                      />
                    )}
                    {isDetailPage && state.hasShare && (
                      <>
                        <TextInput
                          key={'shareLabelText'}
                          label='Share Label Text'
                          onChange={handleTextChange('shareLabelText')}
                          value={state.shareLabelText || ''}
                          disabled={hasInputsDisabled}
                        />
                        <TextInput
                          key={'shareLink'}
                          label='Custom Share Link'
                          description='Leave blank to keep default deeplink app sharing of article'
                          onChange={handleTextChange('shareUrl')}
                          value={state.shareUrl || ''}
                          disabled={hasInputsDisabled}
                        />
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
            {isDetailPage && (
              <div className='h-screen mt-5 lg:mt-0 lg:col-span-1 sm:col-span-3'>
                <div className=''>
                  <span className='flex justify-between'>
                    <h3 className='text-lg font-medium text-gray-900'>
                      Map Coordinates
                      {isInvalidField('location') && (
                        <span className='text-red-600 ml-1'>*</span>
                      )}
                    </h3>
                    {pageAction === PageActionVariant.EDIT && (
                      <Button
                        variant={ButtonVariant.BLUE_RESET}
                        onClick={handleResetMapCoordinates}
                        type='button'
                        title='Reset the map location to previous saved value'
                        hasIcon
                        iconVariant={ButtonIconVariant.RESET}
                        className='text-sm'
                      >
                        Reset Map
                      </Button>
                    )}
                  </span>
                  <div className='flex justify-between'>
                    <div>
                      <div>latitude: {state.location?.lat || 'invalid'}</div>
                      <div>longitude: {state.location?.lon || 'invalid'}</div>
                    </div>
                  </div>
                  <p className='my-2 text-sm text-gray-600'>
                    Click the map to drop a pin or use the searchbox to find a
                    place or enter a coordinate.
                  </p>
                </div>
                <GeocoderMap
                  variant={MapVariant.GEOCODER_INPUT}
                  disabled={hasInputsDisabled}
                  markers={
                    state.location
                      ? [
                          {
                            id: 'droppedMarker',
                            name: 'Details Page Marker',
                            ...state.location,
                          },
                        ]
                      : []
                  }
                  containerStyle={{
                    width: '100%',
                    height: '75%',
                  }}
                  hasMapClick
                  onCoordinates={handleCoordinates}
                />
              </div>
            )}
          </div>
        </form>
      ) : (
        <div className={'flex items-center justify-center'}>
          <Loader height={60} width={60} />
        </div>
      )}
    </div>
  )
}

export default FormPage
