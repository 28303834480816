import { createClient, isPublished } from 'contentful-management'

export interface FileData {
  url?: string // resolved fileUrl from FileReader
  contentType: string
  fileName: string
  upload: string // must be a ArrayBuffer
  replacementId?: string // conentfulAssetId for replacement
}

export enum Environment {
  MASTER = 'master',
}

export enum ContentfulCollection {
  APP_SETTINGS_COLLECTION = 'mobileAppSettingsCollection',
  DETAIL_PAGE_COLLECTION = 'detailPageCollection',
  DRAWER_ITEM_COLLECTION = 'drawerItemCollection',
}

export enum ContentfulContentId {
  DETAIL_PAGE = 'detailPage',
  APP_SETTINGS = 'mobileAppSettings',
  DRAWER_ITEM = 'drawerItem',
}

export interface ContentfulApiProps {
  clientSpace: string // clientSpace key
  accessToken: string // personal user access token or oauth token this is a different token from
}

enum Locale {
  US = 'en-US',
}

class ContentfulApi {
  client: Record<string, any>
  clientSpace: string

  constructor(props: ContentfulApiProps) {
    this.clientSpace = props.clientSpace
    this.client = createClient({
      accessToken: props.accessToken,
    })
  }

  private getSpace = async () => {
    try {
      const space = await this.client.getSpace(this.clientSpace)
      return space
    } catch (e) {
      console.error('Something went wrong when getting contentful space', e)
      return false
    }
  }

  private getEnvironment = async () => {
    const space = await this.getSpace()

    try {
      const environment = await space.getEnvironment(Environment.MASTER)
      return environment
    } catch (e) {
      console.error('error while fetching environment', e)
      return false
    }
  }

  private postEntry = async (
    contentTypeId: ContentfulContentId,
    data: Record<string, any>,
    updateOnly: boolean = false
  ) => {
    try {
      const environment = await this.getEnvironment()
      const entry = await environment.createEntry(contentTypeId, data)

      if (updateOnly) {
        return entry
      }

      const publishedEntry = await entry.publish()

      return publishedEntry
    } catch (e) {
      console.error('error while posting an entry', e)
      return false
    }
  }

  public unpublishEntry = async (
    entryId: string,
    entryData: Record<string, any>
  ) => {
    const environment = await this.getEnvironment()
    const entry = entryData || (await environment.getEntry(entryId))

    try {
      const unpublishedEntry = entry.unpublish()
      return unpublishedEntry
    } catch (e) {
      console.log('something went wrong with unpublishing your entry', e)
      return false
    }
  }

  public deleteEntry = async (entryId: string) => {
    const environment = await this.getEnvironment()
    let entry = await environment.getEntry(entryId)

    if (entry.mediaImage) {
      if (entry.mediaImage.sys.id) {
        await this.deleteAsset(entry.mediaImage.sys.id)
      }
    }

    try {
      if (isPublished(entry)) {
        // Unpublishing hides it from production
        entry = await this.unpublishEntry(entryId, entry)
      }

      // Takes the unpublished entry and deletes it, there is no response object for a deletion
      await entry.delete()
      return true
    } catch (e) {
      console.error(`error while deleting ${entryId}`, e)
      return false
    }
  }

  /**
   *
   * Support url uploads of image
   */
  public uploadImageAsset = (assetData: FileData & { url: string }) => {
    if (
      !assetData.upload &&
      assetData.url.match(new RegExp('(.jpg|.jpeg|.png|.gif)'))
    ) {
      return this.uploadImageAssetFromUrl(assetData)
    }
    return this.uploadImageAssetFromBuffer(assetData)
  }

  public uploadImageAssetFromBuffer = async (assetData: FileData) => {
    const environment = await this.getEnvironment()

    try {
      const upload = await environment.createUpload({
        file: assetData.upload,
        contentType: assetData.contentType,
        fileName: assetData.fileName,
      })

      if (!upload?.sys?.id) {
        console.error('failed to upload, missing uploadId')
        return false
      }

      const createdAsset = await environment.createAsset({
        fields: {
          title: this.setLocale(assetData.fileName, Locale.US),
          file: this.setLocale(
            {
              fileName: assetData.fileName,
              contentType: assetData.contentType,
              uploadFrom: {
                sys: {
                  type: 'Link',
                  linkType: 'Upload',
                  id: upload.sys.id,
                },
              },
            },
            Locale.US
          ),
        },
      })

      const processedAsset = await createdAsset.processForLocale(Locale.US, {
        processingCheckWait: 2000,
      })

      const publishedAsset = await processedAsset.publish()

      return publishedAsset
    } catch (e) {
      console.error('Error while creating asset', e)
      return false
    }
  }

  /**
   * Useful for app migration
   */
  public uploadImageAssetFromUrl = async (assetData: FileData) => {
    const environment = await this.getEnvironment()

    const fileData = {
      fields: {
        title: this.setLocale(assetData.fileName, Locale.US),
        file: this.setLocale(
          {
            contentType: assetData.contentType,
            fileName: assetData.fileName,
            upload: assetData.upload,
          },
          Locale.US
        ),
      },
    }

    try {
      const createdAsset = await environment.createAsset(fileData)

      const processedAsset = await createdAsset.processForLocale(Locale.US, {
        processingCheckWait: 2000,
      })

      const publishedAsset = await processedAsset.publish()

      return publishedAsset
    } catch (e) {
      console.error('Error while creating asset', e, assetData)
      return false
    }
  }

  /**
   * No used in the current CMS but useful to updating image assets
   */
  public updateAsset = async (assetId: string, fields: any) => {
    const environment = await this.getEnvironment()
    try {
      const asset = await environment.getAsset(assetId)
      // asset.fields.title['en-US'] = 'New asset title'
      const updatedAsset = await asset.update()

      return asset
    } catch (e) {
      console.error(e)
    }
  }

  public deleteAsset = async (assetId: string) => {
    if (!assetId) {
      console.warn('no assetId provided to delete')
      return
    }

    const environment = await this.getEnvironment()
    const asset = environment.getAsset(assetId)

    try {
      await asset.delete()
      return true
    } catch (e) {
      console.error(`asset deletion failed for ${assetId}`, e)
      return false
    }
  }

  private setLocale = (
    entry: Record<string, any> | string,
    locale: Locale
  ) => ({
    [locale]: entry,
  })

  public createLocalizedRequest = (
    request: Record<string, any>,
    locale = Locale.US
  ) =>
    Object.entries(request).reduce<any>(
      (acc, [key, val]: [string, any]) => {
        // strip out typename to avoid api errors
        if (key === '__typename' || key === 'id' || key === 'sys') {
          return acc
        }

        if (key === 'location' && val.__typename) {
          acc.fields[key] = this.setLocale(
            { lat: val.lat, lon: val.lon },
            locale
          )
          return acc
        }

        acc.fields[key] = this.setLocale(val, locale)
        return acc
      },
      { fields: {} } as Record<string, any>
    )

  public updateContentPage = async (
    entryId: string,
    data: Record<string, any>,
    updateOnly: boolean = false
  ) => {
    const { uploadMediaImage, mediaImage, ...restData } = data

    try {
      const environment = await this.getEnvironment()
      const entry = await environment.getEntry(entryId)

      /**
       * Combines pre-existing field data with updated data
       */
      const combinedData = {
        fields: {
          ...entry.fields,
          ...this.createLocalizedRequest(restData).fields,
        },
      }

      entry.fields = combinedData.fields

      /**
       * mediaImage should not be included if the file does not change
       */
      if (uploadMediaImage) {
        const uploadedImageAsset = await this.uploadImageAsset(uploadMediaImage)

        // See FileData type for reference
        if (uploadMediaImage.replacementId && uploadedImageAsset?.sys.id) {
          await this.deleteAsset(uploadMediaImage.replacementId)
        }

        if (!uploadedImageAsset?.sys.id) {
          console.error('error uploading')
          return
        }

        // Link the entry mediaImage to the uploaded asset
        entry.fields.mediaImage = this.setLocale(
          {
            sys: {
              id: uploadedImageAsset.sys.id,
              linkType: 'Asset',
              type: 'Link',
            },
          },
          Locale.US
        )
      }

      const updatedEntry = await entry.update()
      const publishedEntry = await updatedEntry.publish()

      return publishedEntry
    } catch (e) {
      console.error('Error occured', e)
      return false
    }
  }

  public createContentPage = async (
    data: Record<string, any>,
    contentId: ContentfulContentId,
    updateOnly: boolean = false
  ) => {
    const { uploadMediaImage, mediaImage, ...restData } = data

    const localizedData = this.createLocalizedRequest(restData)

    try {
      if (uploadMediaImage) {
        const uploadImageAsset = await this.uploadImageAsset(uploadMediaImage)

        // Link the entry mediaImage to the uploaded asset
        localizedData.fields.mediaImage = this.setLocale(
          {
            sys: {
              id: uploadImageAsset.sys.id,
              linkType: 'Asset',
              type: 'Link',
            },
          },
          Locale.US
        )
      }

      const createdEntry = await this.postEntry(
        contentId,
        localizedData,
        updateOnly
      )

      return createdEntry
    } catch (e) {
      console.error('there was an error with creating the detail page', e)
      return false
    }
  }
}

export default ContentfulApi
