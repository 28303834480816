import { memo, useState, FC, useEffect } from 'react'
import mapboxgl from 'mapbox-gl'
import ReactMapboxGl, { Marker } from 'react-mapbox-gl'
import { FitBounds } from 'react-mapbox-gl/lib/map'

import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'

import { updateGeocoder, coordinatesGeocoder } from './Map.helpers'
import { ReactComponent as Place } from '../../assets/pin.svg'
import './Map.style.css'
import classNames from 'classnames'
import { FileMediaTypes } from '../Inputs/DragDropUpload'

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

// Grabbed this token from the previous CMS
const mapBoxToken =
  'pk.eyJ1IjoiZWxzZXdpc2UiLCJhIjoiY2p6aXplZmRpMDRvZjNucHA4Nm9kOGJ4aiJ9.WhBKEk0BHrGkzJF3DWAfug'

export const OPAQUE_RASTER_SOURCE = {
  type: 'raster',
  id: 'translucentSource',
  tileUrlTemplates: [
    'https://c.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png',
  ],
  tileSize: 256,
}

interface MarkerProps {
  onHover?: (isFocused: boolean) => () => void
  onClick?: () => void
  id: string
  name: string
  lat: number
  lon: number
  focused?: boolean
  mediaType?: FileMediaTypes
}

export enum MapVariant {
  GEOCODER_INPUT,
  DEFAULT,
}

interface MapProps {
  variant: MapVariant
  containerStyle?: Record<string, any>
  hasMapClick?: boolean
  markers?: MarkerProps[]
  onCoordinates?: (coordinates: { lat: number; lon: number }) => void
  disabled?: boolean
}

const defaultContainerStyle = {
  width: '100vw',
  height: '100vh',
}

// Not able to import 3rd part definition (this is a port)
// type FitBounds = [[number, number], [number, number]]

const mapFitBounds: FitBounds = [
  [-111.479583, 33.627014],
  [-112.467222, 33.196944],
]

const mapBoundaries = [...mapFitBounds[1], ...mapFitBounds[0]]

const geocoder = new MapboxGeocoder({
  accessToken: mapBoxToken,
  localGeocoder: coordinatesGeocoder,
  bbox: mapBoundaries,
  limit: 3,
})
const MapboxGlMap = ReactMapboxGl({
  accessToken: mapBoxToken,
})

/**
 * @TODO add html version so I can inject the root layer items from ADMRN into the CMS
 */
const Map: FC<MapProps> = ({
  containerStyle = defaultContainerStyle,
  disabled = false,
  hasMapClick = false,
  markers = [],
  onCoordinates,
  variant,
}) => {
  const [hasLoaded, setLoaded] = useState(false)
  const [hasDroppedPin, setDroppedPin] = useState(false)
  const [pinCoordinates, setCoordinates] = useState([0, 0])

  useEffect(() => {
    if (disabled || !geocoder || !hasLoaded) {
      return
    }
    // Must be a function for geocoder to properly remove the listener
    function geocoderResultFn({ result }: any) {
      if (result && result?.geometry?.coordinates) {
        const resultCoodinates = result.geometry.coordinates
        setCoordinates(resultCoodinates)

        // Sets initial coordinates
        if (onCoordinates) {
          onCoordinates({ lon: resultCoodinates[0], lat: resultCoodinates[1] })
        }
      }
    }

    const geoResultPromise = geocoder.on('result', geocoderResultFn)

    return () => {
      if (geoResultPromise && geocoderResultFn) {
        geoResultPromise.off('result', geocoderResultFn)
      }
    }
  }, [disabled, hasLoaded, onCoordinates])

  /**
   * Geocoder must be initialized with the map before this effect works
   */
  useEffect(() => {
    if (
      variant !== MapVariant.GEOCODER_INPUT ||
      markers.length === 0 ||
      !hasLoaded
    ) {
      return
    }

    const [detailsPageMarker] = markers

    if (detailsPageMarker.lon && detailsPageMarker.lat) {
      setCoordinates([detailsPageMarker.lon, detailsPageMarker.lat])
      setDroppedPin(true)

      if (geocoder && !disabled) {
        updateGeocoder(geocoder, {
          lat: detailsPageMarker.lat,
          lng: detailsPageMarker.lon,
        })
      }
    }
  }, [variant, markers, hasLoaded, disabled])

  const handleMapClick = (map: any, event: any) => {
    const { lng, lat } = event.lngLat

    if (!hasDroppedPin) {
      setDroppedPin(true)
    }

    updateGeocoder(geocoder, { lat, lng })

    setCoordinates([lng, lat])

    if (onCoordinates) {
      onCoordinates({ lon: lng, lat })
    }
  }

  const handleLoaded = (e: any) => {
    if (!!geocoder) {
      e.addControl(geocoder)
    }

    setLoaded(true)
  }

  return (
    <MapboxGlMap
      // eslint-disable-next-line react/style-prop-object
      style='mapbox://styles/mapbox/streets-v9'
      containerStyle={containerStyle}
      fitBounds={mapFitBounds}
      onStyleLoad={handleLoaded}
      onClick={hasMapClick && !disabled ? handleMapClick : undefined}
    >
      <>
        {markers.map((marker) => (
          <Marker key={marker.id} coordinates={[marker.lon, marker.lat]}>
            <div
              className='cursor-pointer'
              onMouseEnter={marker.onHover && marker.onHover(true)}
              onMouseLeave={marker.onHover && marker.onHover(false)}
              onClick={marker.onClick}
            >
              <Place
                title={marker.name}
                className={classNames('fill-current', {
                  'text-indigo-600': marker.focused,
                  'text-black-400':
                    !marker.focused &&
                    marker.mediaType === FileMediaTypes.VIDEO,
                  'text-blue-400':
                    !marker.focused &&
                    marker.mediaType === FileMediaTypes.IMAGE,
                })}
              />
            </div>
          </Marker>
        ))}
        {hasDroppedPin ? (
          <Marker coordinates={pinCoordinates}>
            <Place className='fill-current text-indigo-600' />
          </Marker>
        ) : undefined}
      </>
    </MapboxGlMap>
  )
}

export default memo(Map)
